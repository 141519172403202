@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Grandstander:wght@300&family=Kalam&family=Patrick+Hand&display=swap);

:root {
    --font-family: "Open Sans", sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.custom-toast-container-class {
    white-space: nowrap;
    width: max-content !important;
    max-width: none ;
}

.username {
    color: #fff
}


#root{
    overflow-x: hidden;
}


body {
    font-family: var(--font-family) !important;
    transition: all 0.4s;
    position: relative;
    overflow-x: hidden;
    font-size: 16px;
    scroll-behavior: smooth;
    background-color: #110502;
}

body #background-video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

@media only screen and (max-width: 991.98px) {
    html {
        font-size: 14px;
    }

    .pb-115 {
        padding-bottom: 75px !important;
    }

    .pb-83 {
        padding-bottom: 83px;
    }
    .contact-form {
        flex-direction: column;
    }
    .contactdetails {
        margin-left: auto!important;
        margin-right: auto;
        margin-top: 27px;
        width: 633px;
    }
}

@media only screen and (max-width: 575.98px) {
    html {
        font-size: 12px;
    }

    .pb-328 {
        padding-bottom: 228px !important;
    }
}

ul {
    list-style: none;
    padding-left: 0;
}

section {
    position: relative;
}

.section-head {
    color: #fff;
    text-align: center;
    font-size: 2.81rem;
    font-weight: 700;
    line-height: 120%;
}


a {
    transition: 0.3s;
    text-decoration: none;
}

ul {
    list-style: none;
    margin-bottom: 0;
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-83 {
    padding-bottom: 83px;
}

.pb-328 {
    padding-bottom: 328px;
}

.price {
    display: flex;
    gap: 8px;
    font-family: "Nunito Sans", sans-serif;
}

.price .india {
    color: #fc6f2f;
    text-align: justify;
    font-size: 1.25rem;
    font-weight: 700;
}

.price .usa {
    color: #fc6f2f;
    text-align: justify;
    font-size: 1.25rem;
    font-weight: 700;
}

.rate {
    display: flex;
    align-items: center;
    gap: 8px;
}

.rate span {
    color: rgba(0, 10, 43, 0.6);
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.13px;
}

.rate i {
    color: #244bd6;
}

.text-blue {
    color: rgba(0, 19, 83, 0.7);
}

.font-small {
    font-size: 10px !important;
}

@media (min-width: 995.99px) {
    .mobile {
        display: none !important;
    }

    .desktop {
        display: flex !important;
    }

    .nav-top .navbar-collapse {
        display: none !important;
    }
}

@media (max-width: 995px) {
    .mobile {
        display: flex !important;
    }

    .desktop {
        display: none !important;
    }
}

@media (max-width: 1200px) {
   .search.position-relative{
    display: none;
   }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        display: none !important;
        flex-basis: auto;
    }
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
input,
p {
    transition: 0.4s;
    margin-bottom: 0;
}

header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(7.5px);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.logo+.btn.primary {
    padding: 0 25px;
    height: 40px;
}

.nav-container .nav-link a {
    color: rgba(255, 255, 255, 0.85);
    text-align: center;
    font-size: 1.12rem;
    font-weight: 400;
    letter-spacing: 0.54px;
    transition: 0.4s;
}

.nav-container .nav-link a:hover {
    color: #fc6f2f;
}

.navbar-toggler {
    border: 1px solid #fff;
    box-shadow: none !important;
}

.navbar-toggler .navbar-toggler-icon {
    filter: invert(1);
}

.nav-top .navbar-collapse {
    position: absolute;
    width: 100%;
    height: 110vh;
    left: 0;
    top: 0;
    bottom: 0;
    background: #000;
    z-index: 999;
    overflow: hidden;
}

.nav-top .navContent {
    width: 100%;
    height: 100vh;
    gap: 20px;
    padding: 36px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.nav-top .navContent .nav-list {
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: column;
}

.nav-top .navContent .nav-list .nav-link a::after {
    display: none;
}

.nav-top .closeNav {
    position: absolute;
    right: 15px;
    top: 37px;
    color: #fff;
    font-size: 18px;
}

.navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.nav-top {
    display: flex;
    align-items: center;
    height: 91px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    width: 100%;
}

.nav-top .nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.nav-top .nav-list {
    display: flex;
    gap: 40px;
    transition: 300ms;
}

.nav-top .nav-list .nav-link a {
    font-size: 1.1rem;
    position: relative;
}

.nav-top .nav-list .nav-link a::after {
    content: "";
    position: absolute;
    right: -12px;
    top: 50%;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.85);
}

.nav-top form {
    position: relative;
}

.nav-top form input {
    all: unset;
    display: flex;
    max-width: 216px;
    height: 48px;
    padding: 0 10px 0 28px;
    align-items: center;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.26);
    backdrop-filter: blur(10px);
    color: rgba(255,255,255,0.9);
    font-size: 0.95rem;
    transition: 300ms;
}

.nav-top .search .search-result li {
    font-size: 12px;

}
.nav-top .search .search-result li .course-img{
    flex:0 0 35px;
    padding-top: 5px;
}
.nav-top .search .search-result li .course-img img{
    height: 25px;
    width: 30px;
    object-fit: cover;
}

.nav-top .search .search-result li:hover {
    background-color: #f2f2f2;
}

.nav-top form input::placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.95rem;
}

.nav-top form span {
    position: absolute;
    right: 28px;
    top: 50%;
    transform: translateY(-50%);
}

.nav-top form span i {
    font-size: 1.25rem;
}

.nav-top .buttons-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.nav-top .buttons-container .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    padding: 0 !important;
    color: #fff;
    background-color: transparent;
    border-radius: 50px;
    border: 1.5px solid rgba(255, 255, 255, 0.25);
    font-size: 1rem;
    transition: 500ms;
}

.nav-top .buttons-container .circle i {
    font-size: 1rem;
    transition: 500ms;
}

.nav-top .buttons-container .circle:hover{
    background-color: rgba(36,75,214,0.5);
    border-color: transparent;
}

.nav-top .buttons-container .primary {
    padding: 0 25px;
    max-height: 48px;
}

.nav-bottom {
    display: flex;
    align-items: center;
    height: 51px;
    width: 100%;
}

.nav-bottom .nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav-bottom .nav-list {
    display: flex;
    gap: 50px;
}

@media (min-width: 1200.99px) and (max-width: 1400px) {
    .nav-top .nav-list {
        gap: 20px;
    }

    .nav-top .nav-container {
        gap: 10px;
        /* flex: 1; */
    }
}

@media only screen and (max-width: 991.98px) {
    .nav-bottom .nav-list {
        gap: 10px;
    }
}

@media only screen and (max-width: 575.98px) {
    .nav-top .logo {
        width: 35%;
    }

    .nav-top .logo img {
        width: 100%;
        max-width: 155px;
    }

    .nav-top .buttons-container {
        gap: 10px;
    }

    .nav-top .buttons-container .circle {
        width: 40px;
        height: 40px;
    }

    .nav-top .buttons-container .primary {
        height: 40px;
    }

    .nav-bottom .nav-list {
        flex-wrap: wrap;
        gap: 5px;
    }

    .nav-bottom .nav-list .nav-link a {
        font-size: 12px;
        text-wrap: nowrap;
    }
    .nav-top form input::placeholder {
        color: rgba(255, 255, 255, 0.8);
        font-size: 1.2rem;
    }
    .nav-top .nav-list .nav-link a {
        font-size: 1.4rem;
    }
}

.footer {
    background-color:#010617;
    position: relative;
}

.footer::before {
    content: "";
    background: linear-gradient(0deg,
            rgba(0, 0, 0, 0.2) 0,
            rgba(0, 0, 0, 0.2) 100%),
        url(../../../public/images/background.webp),
        #d3d3d3 -24.5px 0/103.657% 133.046% no-repeat;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.footer__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 35px 0;
    gap: 63px;
}

.footer__links {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}

.footer__links a {
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
}

.copyright {
    background: var(--copyright-bg);
    backdrop-filter: blur(2.5px);
    padding: 35px 0 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.copyright__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.copyright__content .social_media {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 33px;
}

.copyright__content .social_media h3 {
    color: rgba(255,255,255,0.4);
    font-size: 1.12rem;
    font-weight: 700;
    line-height: 144.4%;
    letter-spacing: 0.18px;
}

.copyright__content ul {
    display: flex;
    gap: 10px;
}

.copyright__content ul li svg {
    width: 35px;
    height: 35px;
    transition: 0.4s;
}

.copyright__content ul li svg:hover * {
    fill: #fc6f2f;
}

.copyright__content ul li img {
    width: 35px;
    height: 35px;
    transition: 0.1s;
}

.copyright__content ul li img:hover {
    filter: invert(50%) sepia(50%) saturate(2095%) hue-rotate(341deg) brightness(103%) contrast(98%);
}

.copyright p {
    color: rgba(255,255,255,0.4);
    font-size: 1rem;
    font-weight: 400;
    line-height: 144.4%;
    letter-spacing: 0.16px;
    text-align: center;
}

.copyright p a {
    color: rgba(255,255,255,0.4);
    font-size: 1rem;
    font-weight: 600;
    line-height: 144.4%;
    letter-spacing: 0.16px;
    text-decoration-line: none;
}

@media only screen and (max-width: 991.98px) {
    .footer__content {
        gap: 20px;
    }

    .footer__links {
        gap: 20px;
    }
}

@media only screen and (max-width: 575.98px) {
    html {
        font-size: 12px;
    }
}

.contact-form {
    align-items: center;
    /* background: linear-gradient(331deg,#000d3b80 10.96%,#04196a80 98.75%),linear-gradient(5deg,#00104680 23.53%,#06154e80 95.85%); */
    border-radius: 0px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1015px;
    min-height: 445px;
    overflow: hidden;
    padding: 30px;
    position: relative;
    width: 100%;
    padding-top: 135px;
}


/* .contact-form:after{
    content: '';
    display: block;
    width: 230px;
    height: 230px;
    position: absolute;
    left: 0px;
    bottom:0px;
    background: url(../../../public/images/celebration-icon.svg)no-repeat left bottom;
    background-size: contain;
} */

/* .contact-form:before{
    content: '';
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 50%;
    top:-60px;
    right: 13vw;
    background-color: rgba(255, 255, 255, 0.15);
    position: absolute;
    pointer-events: none;
} */

.contact-form .content {
    width: 727px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
    gap: 50px;
    position: relative;
    z-index: 99;
}


.contact-form .content h2 {
    color: #fff;
    text-align: left;
    font-size: 2.81rem;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 20px;
}

.contact-form .content p {
    color: #fff;
    text-align: left;
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.contact-form .content form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 35px 0;
    width: 438px;
    max-width: 100%;
}

.contact-form .content form>div {
    width: 100%;
}

.contact-form .content form .btn {
    width: 100%;
}

.contact-form .content span {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
    font-weight: 400;
}

.contact-form .content span a {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    text-decoration-line: underline;
}

.course {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.course .course__image {
    max-height: 260px;
    border-radius: 20px;
    overflow: hidden;
}

.course .course__image img {
    height: 100%;
    object-fit: cover;
}

.course h3 {
    color: #fff;
    font-size: 1.12rem;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.course p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
}

.skillbakery-badge {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 20px;
    align-items: center;
    gap: 30px;
    border-radius: 15px;
    background: #fff;
    width: fit-content;
}

.skillbakery-badge__info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

.skillbakery-badge__info img {
    width: 62px;
    height: 62px;
}

.skillbakery-badge__info .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.skillbakery-badge__info .info h3 {
    color: var(--TExt-color, #001353);
    text-align: center;
    font-size: 1.12rem;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.skillbakery-badge__info .info p {
    color: rgba(0, 19, 83, 0.85);
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
}

.feature {
    position: relative;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
}

.feature__head {
    height: 225px;
}

.feature__head img {
    height: 100%;
    object-fit: cover;
}

.feature__head span {
    position: absolute;
    left: 23px;
    top: 18px;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
}

.feature__body {
    display: flex;
    flex-direction: column;
    padding: 24px 30px;
    gap: 20px;
    min-height: 355px;
}

.feature__body .feature-price {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    flex-direction: column;
}

.feature__body .feature-price .category {
    padding: 8px 20px;
    border-radius: 15px;
    background: rgba(252, 111, 47, 0.86);
    color: #fff;
    font-size: 0.93rem;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.15px;
}

.feature__body .feature-title {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.feature__body .feature-title h3 {
    color: var(--TExt-color, #001353);
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 140%;
}

.feature__body .feature-title p {
    color: rgba(0, 19, 83, 0.9);
    font-size: 1.12rem;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.05px;
    font-family: "Nunito Sans", sans-serif;
}

.feature__body .feature-info {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
}

.feature__body .feature-info>div {
    display: flex;
    gap: 8px;
    align-items: center;
}

.feature__body .feature-info>div i {
    font-size: 1.1rem;
    color: rgba(49, 58, 88, 0.9);
}

.feature__body .feature-info>div span {
    color: rgba(0, 10, 43, 0.6);
    font-size: 0.97rem;
    font-weight: 600;
    line-height: 150%;
}

.feature__body .feature-rate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.feature__body .feature-rate .buttons {
    display: flex;
    gap: 15px;
    align-items: center;
    flex: 1;
    justify-content: end;
}

button {
    border: 0;
    background-color: transparent;
}

.btn {
    display: flex;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0;
    transition: 0.4s ease-in-out;
    border: 1px solid transparent;
}

.btn.primary {
    background: #fc6f2f;
    color: #fff;
    height: 60px;
}

@media only screen and (max-width: 575.98px) {
    .btn.primary {
        height: 50px;
    }
}

.btn.primary:hover {
    background: 0 0;
    border: 1px solid #fc6f2f;
    color: #fc6f2f;
}

.btn.secondary {
    background: #153bc5;
    color: #fff;
    padding: 19px 0;
}

.btn.secondary:hover {
    background: 0 0;
    border: 1px solid #153bc5;
    color: #153bc5;
}

.btn.green {
    background: #00b232;
    color: #fff;
    padding: 17px 30px;
    max-width: 300px;
}

.btn.green:hover {
    background: 0 0;
    border: 1px solid #00b232;
    color: #00b232;
}

.btn.mint {
    background: #22b491;
    color: #fff;
    padding: 17px 30px;
    max-width: 300px;
}

.btn.mint:hover {
    background: 0 0;
    border: 1px solid #22b491;
    color: #22b491;
}

.btn.outline {
    background: 0 0;
    color: #314074;
    border: 1px solid #314074;
}

button.buy {
	display: flex;
	padding: 4px 12px 6px 12px !important;
	justify-content: center;
	align-items: center;
	border-radius: 25px;
	background-color: #fc6f2f;
	font-size: 1.05rem;
	color: #fff;
	border: 1px solid transparent;
	transition: 0.4s;
	width: auto;
	height: 45px;
	transition: 100ms;
	gap: 4px;
	font-weight: 500;
    min-width: 80px;
}

button.buy.rtc img{
    width: 22px;
}

button.buy.rent{
    background-color: #7441a3;
    transition: all 0.3s ease-in-out;
}

button.buy.rent:hover{
    background-color: #39135a;
    border: 1px solid transparent;
}

button.buy.rtc{
    background-color: #7441a3!important;
    border: 1px solid #fff;
}
button.buy.rtc:hover{
    background-color: #8b28e6!important;
    border: 1px solid #fff;
}

.coursePopup button.buy.rtc{
    border: 1px solid #7441a3!important;
}
button.buy:hover {
    background: #e24f0b;
    border: 1px solid #fc6f2f;
}
button.buy:active{
    transform: scale(1.07);
}

.details-btn {
    display: flex;
    padding: 11px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 1.5px solid rgba(0, 19, 83, 0.50);
    color: rgba(0, 19, 83, 0.75);
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    transition: 0.2s ease-in-out;
    letter-spacing: -0.5px;
}

.details-btn:hover{
    background-color: #eee;
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    overflow: hidden;
    margin-bottom: 50px;
    margin-top: 60px;
}

.controls .control {
    font-size: 1.12rem;
    font-weight: 400;
    letter-spacing: 0.54px;
    border: 1.5px solid rgba(255, 255, 255, 0.6);
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 50px;
    text-wrap: nowrap;
    transition: 0.4s;
}

.controls .control.active {
    color: #fff;
    background: var(--Active-color-2, #fc6f2f);
}

.subscribe {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    padding: 17px 25px;
    border-radius: 50px;
    background: #d8231b;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
    transition: 400ms;
}

.subscribe:hover{
    background: #ac1e16;
}

.ownPeace .section-head {
    margin-bottom: 60px;
}

.ownPeace__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.ownPeace__card i {
    font-size: 4.87rem;
    background: -webkit-linear-gradient(#ff9362, #fc6f2f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.ownPeace__card .icon {
    min-height: 78px;
}


.ownPeace__card h3 {
    color: #fff;
    text-align: center;
    font-size: 2.18rem;
    font-weight: 600;
    line-height: 130%;
}

.ownPeace__card p {
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    width: 70%;
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.subscription .section-head {
    margin-bottom: 20px;
}

.subscription .section-head+p {
    margin-bottom: 60px;
    color: #fff;
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.subscription__card {
    background-color: #fff;
    border-radius: 0px;
    padding: 30px 34px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: 500ms;
}
.subscription__card:hover{
    transform: scale(1.13);
    z-index: 2;
}

.subscription__card h3 {
    color: rgba(0, 19, 83, 0.85);
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.3px;
    font-family: 'Nunito Sans';
}

.subscription__card h3 strong {
    font-weight: 700;
}

.subscription__card .buttons-container {
    display: flex;
    gap: 0px;
    justify-content: center;
    font-size: 0.85rem;
    font-weight: 500;
    opacity: 0.7;
}

.subscription__card .buttons-container button {
    display: flex;
    padding: 17px 30px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 50px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 19px;
}

.subscription__head {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscription__head .price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.subscription__head .price .price__now .india {
    color: var(--For-hover-buttons, #4a5784);
    font-family: Open Sans;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    font-family: 'Nunito Sans';
    margin-top: -2px;
    display: inline-block;
}

.subscription__head .price .price__now .usa {
    color: var(--For-hover-buttons, #4a5784);
    font-family: Open Sans;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    font-family: 'Nunito Sans';
    margin-top: -2px;
    display: inline-block;
}

.subscription__head .price .price__before {
    color: rgba(0, 10, 43, 0.8);
    font-family: Open Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.6;
    text-decoration: line-through;
    text-decoration-color: #ef4444;
}

.subscription__head .discount {
    display: inline-flex;
    padding: 4px 15px 3px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #DBEAFE;
    color: #1D4ED8;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    line-height: 24px;
    order: 1;
    border: 1px solid #93C5FD;
}

@media only screen and (max-width: 991.98px) {
    .subscription__card {
        gap: 15px;
        padding: 20px;
    }

    .subscription__card .buttons-container {
        flex-wrap: wrap;
    }

    .subscription__head .price {
        gap: 10px;
    }
}

.testimonials {
    overflow: hidden;
}

.testimonials .section-head {
    margin-bottom: 20px;
    position: relative;
}

.testimonials .section-head>span {
    position: absolute;
}

.testimonials .section-head>span .paper {
    right: -85px;
    bottom: -2px;
}

.testimonials .section-head+p {
    margin-bottom: 60px;
    color: #fff;
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.testimonials .testimonial-card {
    transition: 0.4s;
    border-radius: 20px;
    border: 2px solid transparent;
}


.testimonials .testimonial-card:hover {
    border: 2px solid rgba(255, 255, 255, 0.05);
    background: linear-gradient(331deg,
            rgba(0, 13, 59, 0.6) 10.96%,
            rgba(4, 25, 106, 0.6) 98.75%);
    box-shadow: 0 1px 30px 0 rgba(255, 255, 255, 0.05);
}

.testimonials .item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 44px;
}

.testimonials .testimonial-card .item .icon {
    font-size: 3.56rem;
    color: #fff;
}

.testimonials .testimonial-card .item p {
    color: var(--Main-text, rgba(255, 255, 255, 0.85));
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.testimonials .testimonial-card .item h3 {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 150%;
}

@media only screen and (max-width: 991.98px) {
    .testimonials .item {
        padding: 20px;
    }
}

.studio {
    position: relative;
}

.studio .images-container {
    position: relative;
}

.studio .images-container img.shape {
    position: absolute;
    left: 0;
    z-index: -1;
    top: 220px;
    width: 758px;
    max-width: 100%;
}

.studio .images-container img.mac {
    width: 538px;
    max-width: 100%;
    margin-top: 50px;
    height: auto;
}

.studio .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 60px;
}

.studio .content__head {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.studio .content__head h2 {
    text-align: left;
}

.studio .content__head p {
    color: #fff;
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.studio .content__info p {
    color: var(--Main-text, rgba(255, 255, 255, 0.85));
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.studio .content button {
    margin-top: 40px;
    padding: 0 45px;
}

@media only screen and (max-width: 991.98px) {
    .studio .content {
        margin-top: 40px;
        gap: 20px;
    }
}

.modal.cartPopup .modal-dialog {
    width: 920px;
    max-width: 95%;
}

.modal.cartPopup .modal-content {
    width: 920px;
}

.modal.cartPopup .modal-content .modal-body {
    padding: 53px 62px 42px;
    gap: 20px;
    align-items: flex-start;
}

.modal.cartPopup .modal-content .modal-body__head h3 {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.modal.cartPopup .modal-content .modal-body__head h3 .icon {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 6px 6px 7px 7px;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    color: #fff;
    border-radius: 50px;
    background: #12aa65;
}

.modal.cartPopup .modal-content .modal-body__head h3 span {
    color: var(--TExt-color, #001353);
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
}

.modal.cartPopup .modal-content .modal-body__head p {
    color: var(--TExt-color, #001353);
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.modal.cartPopup .modal-content .modal-body__info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.modal.cartPopup .modal-content .modal-body__info .course {
    border-radius: 20px;
    border: 1.5px solid rgba(0, 0, 0, 0.05);
    background: #fff;
    display: flex;
    flex-direction: row;
    padding: 20px 38px 20px 20px;
    justify-content: flex-start;
    width: 100%;
}

.modal.cartPopup .modal-content .modal-body__info .course__image {
    width: 121px;
    height: 70px;
    border-radius: 10px;
}

.modal.cartPopup .modal-content .modal-body__info .course__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.modal.cartPopup .modal-content .modal-body__info .course__info .base-info {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.modal.cartPopup .modal-content .modal-body__info .course__info .base-info h3 {
    color: #373a24;
    font-size: 1rem;
    font-weight: 700;
    line-height: 140%;
}

.modal.cartPopup .modal-content .modal-body__info .course__info .sub-info {
    display: flex;
    align-items: center;
    gap: 15px;
}

.modal.cartPopup .modal-content .modal-body__info .course__info .sub-info>div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.modal.cartPopup .modal-content .modal-body__info .course__info .sub-info>div i {
    font-size: 1.25rem;
    color: #244bd6;
}

.modal.cartPopup .modal-content .modal-body__info .course__info .sub-info>div span {
    color: rgba(0, 10, 43, 0.6);
    font-size: 0.93rem;
    font-weight: 600;
    line-height: 150%;
}

.modal.cartPopup .modal-content .modal-body__info .course__info .control {
    display: flex;
    width: 108px;
    align-items: center;
    justify-content: space-between;
}

.modal.cartPopup .modal-content .modal-body__info .course__info .control .price .india {
    font-size: 0.93rem;
}

.modal.cartPopup .modal-content .modal-body__info .course__info .control .price .usa {
    font-size: 0.93rem;
}

.modal.cartPopup .modal-content .modal-body__info .course__info .control button {
    display: flex;
    width: 18px;
    height: 19px;
    color: #fc6f2f;
}

.modal.cartPopup .modal-content .modal-body__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
}

.modal.cartPopup .modal-content .modal-body__footer .secondary {
    padding: 19px 40px;
}

.modal.cartPopup .modal-content .modal-body__footer .buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
}



.modal.coursePopup .modal-dialog {
    width: 100%;
    max-width: 100%;
}

.modal.coursePopup .modal-content {
    width: 100%;
    max-width: unset;
    background-color: #111827;
}

.modal.coursePopup .modal-content .cousreDetails {
    width: 100%;
}

.modal.coursePopup .modal-content .cousreDetails>div {
    width: 100%;
}

.modal.coursePopup .modal-content .cousreDetails__video iframe,
.modal.coursePopup .modal-content .cousreDetails__video video {
    width: 100%;
    height: 412px;
    max-width: 100%;
}

.modal.coursePopup .modal-content .cousreDetails__video iframe.yotube-video {
    width: 100%;
    height: 500px;
    max-width: 100%;
}

.modal.coursePopup .modal-content .cousreDetails__mainInfo {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.modal.coursePopup .modal-content .cousreDetails__mainInfo .title {
    display: flex;
    justify-content: space-between;
}

.modal.coursePopup .modal-content .cousreDetails__mainInfo .title h3 {
    color: rgba(255,255,255,0.9);
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
    font-family: "Nunito Sans", sans-serif;
}

.modal.coursePopup .text-blue {
    color: rgba(255,255,255,0.9);
}

.modal.coursePopup .modal-content .cousreDetails__mainInfo .title .lang {
    display: flex;
    gap: 15px;
}

.modal.coursePopup .modal-content .cousreDetails__mainInfo .title .lang .outline {
    padding: 0 20px;
    height: 39px;
    gap: 8px;
}

.modal.coursePopup .modal-content .cousreDetails__mainInfo .title .lang .primary {
    padding: 0 20px;
    height: 39px;
}

.modal.coursePopup .modal-content .cousreDetails__mainInfo .price>div {
    font-size: 1.5rem;
}

.modal.coursePopup .modal-content .cousreDetails__mainInfo .buyContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
}

.modal.coursePopup .modal-content .cousreDetails__mainInfo .buyContainer .buy {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.modal.coursePopup .modal-content .cousreDetails__mainInfo .buyContainer .cart {
    width: 60px;
    height: 60px;
    border-radius: 50px;
}

.modal.coursePopup .modal-content .cousreDetails__subInfo .nav {
    display: flex;
    gap: 30px;
    border: none;
    margin-bottom: 25px;
}

.modal.coursePopup .modal-content .cousreDetails__subInfo .nav li button {
    position: relative;
    padding: 0;
    color: var(--For-hover-buttons, #4a5784);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 140%;
    font-family: "Nunito Sans", sans-serif;
    border: none;
    transition: 0.4s;
}

.modal.coursePopup .modal-content .cousreDetails__subInfo .nav li button.active {
    background: var(--Linear,
            linear-gradient(144deg, #ff9362 21.05%, #fc6f2f 82.43%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.modal.coursePopup .modal-content .cousreDetails__subInfo .nav li button.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 1px;
    background: var(--Linear,
            linear-gradient(144deg, #ff9362 21.05%, #fc6f2f 82.43%));
}

.modal .modal-content {
    width: 461px;
    max-width: 95%;
    border: none;
    border-radius: 20px;
    background: #f4f4f4;
    box-shadow: 0 0 54px 0 rgba(255, 255, 255, 0.1), 0 4px 70px 0 rgba(0, 0, 0, 0.3);
}

.modal .modal-fullscreen .modal-content {
    border-radius: 0px;
    color: rgba(255,255,255,0.80);
}
.modal .modal-fullscreen .modal-content p > strong{
    color:rgba(255,255,255,0.90) ;
    font-weight: 700;
}

.modal .modal-body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 45px 40px 45px;
    gap: 20px;
}

.modal .modal-body .btn-close {
    position: absolute;
    right: 12px;
    top: 14px;
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 1;
    padding: 0;
    color: #000;
    transition: 1000ms;
}
.modal .modal-body .btn-close:hover {
    background-color: #cdcdcd;

}

.modal .modal-body__banner {
    text-align: center;
}

.modal .modal-body__banner i {
    background: linear-gradient(85deg, #274fda 0.9%, #0b31ba 89.58%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;
}

.modal .modal-body__banner h3 {
    text-align: center;
    font-size: 2.81rem;
    font-weight: 700;
    line-height: 120%;
    font-family: "Nunito Sans", sans-serif;
    background: linear-gradient(85deg, #274fda 0.9%, #0b31ba 89.58%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 7px;
    letter-spacing: -0.9px;
}

.modal .modal-body__banner p {
    color: rgba(0, 19, 83, 0.7);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.modal .modal-body__form {
    width: 100%;
}

.modal .modal-body__form .buttonsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
}

.modal .modal-body__form .buttonsContainer span {
    color: rgba(0, 19, 83, 0.7);
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 150%;
    display: flex;
    gap: 4px;
}

.modal .modal-body__form .buttonsContainer span button {
    color: #001353;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 150%;
}

.modal .modal-body__form .buttonsContainer button.btn {
    height: 48px;
    margin-bottom: 10px;
}

.modal .modal-body__form .secondary {
	margin-top: 20px;
	padding: 11px 0px;
}

.modal .modal-body__form p {
    color: var(--invert-dark);
    opacity: 0.87;
}

.modal .modal-body__form .forget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
}

.modal .modal-body__form .forget label {
    color: rgba(0, 19, 83, 0.7);
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 150%;
}

.modal .modal-body__form .forget label a {
    color: rgba(0, 19, 83, 0.7);
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 150%;
    text-decoration-line: underline;
}

.modal .modal-body__form .inputsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal .modal-body__form label {
    color: var(--invert-dark);
}


/* ---------------- css added on 22 nov 2023 ---------------- */


.banner-carousel-card{
    position: relative;
}
.banner-carousel-card:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
}

.nav-top .logo {
    display: flex;
    width: 153px;
    cursor: pointer;
    margin-right: auto;
}

.nav-top .logo img {
	animation-name: fadeIn;
	animation-duration: 1s
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

.nav-top .logo:hover img:first-child{
    -webkit-transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
    -ms-transition: all .7s ease-in-out;
	transition: all .7s ease-in-out
}
.nav-top .logo:hover img:last-child{
    margin-left: 5px;
    -webkit-transition: all .5s ease-out;
	-moz-transition: all .5s ease-out;
	-ms-transition: all .5s ease-out;
	transition: all .5s ease-out;
}

.nav-top .nav-list .nav-link .nav-item.dropdown a::after{
   background: none;
}

.dropdown-menu a.dropdown-item{
    color: #4a5784;
    font-size: 1.06rem;
    text-align: left;
}

.custom-dropdown {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  
  .nav-link:hover .custom-dropdown {
    opacity: 1;
    visibility: visible;
  }
  
  .feature__body .feature-title h3 {
    font-size: 1.3rem;
    min-height: 58.22px;
    letter-spacing: -0.5px;
    font-weight: 700!important;
  }
  .feature__body .feature-title p {
    max-height: 54px;
    overflow: hidden;
  }
  .rate {
    gap: 3px;
  }
  .feature__body .feature-info {
    gap: 13px;
    letter-spacing: -0.55px;
  }
  .details-btn {
    font-size: 0.9rem;
  }
  .nav-container .nav-link a{
    color: rgba(255, 255, 255, 0.95);
  }
.dropdown-menu a.dropdown-item{
    color: #4a5784;
}
.sl-title{
    position: relative;
    display: flex;
    padding: 13px 0px 0px 0px;
    margin: 0px 10px;
    justify-content: center;
    align-items: center;
    color: rgba(0, 19, 83, 0.6);
    font-size: 0.90rem;
}
.sl-title::before, .sl-title::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 105px;
    background-color: rgba(192, 192, 192, 0.6);
}

.sl-title::before {
  top: 70%;
  left: 0;
  transform: translateY(-70%);
}

.sl-title::after {
  top: 70%;
  right: 0;
  transform: translateY(-70%);
}
.dflex-row {
    flex-direction: row!important;
    column-gap: 20px;
}
.btn.google {
    background: #fff;
    padding: 17px 30px;
    max-width: 300px;
}

.btn.google:hover {
    background: #f9f9f9;
    border: 1px solid rgba(229,67,53,0.4);
}
.btn.facebook {
    background: #fff;
    padding: 17px 30px;
    max-width: 300px;
}

.btn.facebook:hover {
    background: #f9f9f9;
    border: 1px solid rgba(24,119,242,0.4);
}

.modal .modal-body__banner h3 {
    font-size: 2.5rem;
    background: linear-gradient(85deg, #202124 0.9%, #202124 89.58%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.swipperTab.lessthan1917:before {
    content: '';
    display: flex;
    width: 43px;
    height: 60px;
    background: rgba(255,255,255,0.8) url(../../../public/images/chevron_right.svg)no-repeat right center;
    border-radius: 28px 0px 0px 28px;
    position: absolute;
    right: 0px;
    box-shadow: -1px 0 6px rgba(255,255,255,.6);
    background-size: 35px;
    z-index: 9;
    pointer-events: none;
}

.vertical-scroll {
    overflow-y: auto;
    overflow-x: hidden;
}

.nav-container .nav-link a {
    font-size: 1.1rem;
}

.top-box{
    height: 160px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 60px;
}

.lpath .section-head {
    margin-bottom: 20px;
}

.lpath .section-head+p {
    margin-bottom: 60px;
    color: #fff;
    font-size: 1.20rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}
.lpath__card {
    border-radius: 20px 20px 0px 0px;
    padding: 0px!important;
    display: flex;
    flex-direction: column;
    gap: 15px;
    cursor: pointer;
    color: #fff;
    text-align: center;
    transition: all 0.8s ease-in-out;
    border: none;
    padding: 30px;
    background-image: linear-gradient(rgba(37,61,147,0.3), rgba(11,20,55,1));
}
.lpath__card:hover .icr-box{
    box-shadow: inset 0px 0px 600px 210px #fc6f2f;

    transform: translateY(-5px);
}
.lpath__card:hover .icr-box {
    transform: translateY(0px) scale(2) translateZ(0) zIndex(99999);
    z-index: 0;
}

.lpath__card:hover .ccbx{
    border-top: 2px solid rgba(255, 255, 255, 0.5);
    transform: translateY(-15px);
}
.btn.primary.mw200{
    min-width: 147px;
    display: inline-block;
    margin-top: 30px;

}
.btn.primary.mw200:after{
    content: '';
    position: absolute;
    background: url(../../../public/images/chevron-right.svg)no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    opacity: 0;  
    top: 17px;
    left: 20px;
    color: #fff;
    transition: 0.5s;
}
.btn.primary.mw200:hover::after {
    opacity: 1;
    left: 95px;
  }
.btn.primary.mw200{
    transition: 0.5s;
    position: relative;
}
.btn.primary.mw200:hover{
    padding-right: 24px;
    padding-left:8px;
}

.icr-box {
    background: rgba(255,255,255,1);
    width: 210px;
    height: 210px;
    display: flex;
    border-radius: 50%;
    align-self: center;
    margin-top: 70px;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px 0px 2px 0px rgba(112,112,112,1);
    transition: all 0.3s ease-in-out;
    z-index: 1;
    overflow: hidden;
}
.lpath__card img {
	width: 200px;
	margin-top: 24px;
	margin-bottom: 10px;
    transition: 500ms;
}
.lpath__card:hover img{
    width: 300px;
}
.ccbx {
    border-top: 2px solid rgba(255, 255, 255, 0.15);
    background-image: linear-gradient(#080e26, rgba(11,20,55,1));
    border-radius: 50% 50% 0px 0px;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    transition: all 0.4s ease-in-out;
}
.ccbx:hover .btn.primary.mw200:hover{
    padding-right: 24px;
    padding-left:8px;
}

.lpath__card:hover h4{
    color: #fc6f2f;
}
.lpath__card h4, .lpath__card h6{
    font-weight: 600;
    transition: all 0.8s ease-in-out;
}

.lpath__card h4{
    opacity: 0.95;
}

.lpath__card h6{
    opacity: 0.6;
}

.lpbb{
    color: #fff;
    font-size: 1.3rem;
}
.wi40{
    width: 40px;
}

.roadmap-container {
	max-width: 1024px;
	margin: 50px auto;
	padding: 0px 0px;
	display: flex;
}
  
.roadmap {
    margin-top: 60px;
    color: #fff;
}

.cbbx{
    border: 1px solid rgba(255,255,255,0.15);
    background: linear-gradient(331deg, rgba(0, 13, 59, 0.5) 10.96%, rgba(4, 25, 106, 0.5) 98.75%), linear-gradient(5deg, rgba(0, 16, 70, 0.5) 23.53%, rgba(6, 21, 78, 0.5) 95.85%);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: all 1000ms;
}

.cbbx figure{
    overflow: hidden;
}

.cbbx figure img{
    transition-duration: 7s;
}

.cbbx:hover img{
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    z-index: 0;
}

.cbbx:hover{
    background: linear-gradient(331deg, rgba(0, 13, 59, 0.8) 10.96%, rgba(4, 25, 106, 0.8) 98.75%), linear-gradient(5deg, rgba(0, 16, 70, 0.8) 23.53%, rgba(6, 21, 78, 0.8) 95.85%);
}

.cbbx .btn.primary.gfa {
    position: absolute;
    right: 3px;
    bottom: 0px;
    border-radius: 20px 0px 10px 0px;
    font-family: "Nunito Sans", sans-serif;
}

.roadmap > ul {
    list-style-type: none;
    padding: 0;
}

.roadmap > ul li {
    margin-bottom: 10px;
    position: relative;
}

.roadmap > ul li::before {
    content: '\2022'; 
    font-size: 1.5em; 
    color: #fff; 
    margin-right: 10px; 
    display: inline-block;
    line-height: 1;
}
  
  .roadmap-main {
    display: block;
    justify-content: center;
    align-items: center;
    margin-top:100px;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
  }
  .reset-btn {
    background: #fff;
    padding: 17px 40px;
    max-width: 300px;
    color: #555;
  }
.reset-frm{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.rsp-box {
    background: #f4f4f4;
    width: 460px;
    padding: 32px 40px 60px 40px;
    border-radius: 22px;
    text-align: center;
}
.rsp-box h3{ 
    font-size: 2.5rem;
    background: linear-gradient(85deg, #202124 0.9%, #202124 89.58%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 120%;
    font-family: "Nunito Sans", sans-serif;
}

.h-100{
    height: 100vh!important;
}

.rsp-box p{
    color: rgba(0, 19, 83, 0.7);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}


.history-tl-container{
    font-family: "Roboto",sans-serif;
    margin:auto;
    display:block;
    position:relative;
}
.history-tl-container ul.tl{
    margin:20px 0;
    padding:0;
    display:inline-block;

}
.history-tl-container ul.tl li{
    list-style: none;
    margin:auto;
    margin-left:0px;
    min-height:50px;
    /*background: rgba(255,255,0,0.1);*/
    border-left:1px dashed #86D6FF;
    padding:0 0 50px 30px;
    position:relative;
}
.history-tl-container ul.tl li:last-child{ border-left:0;}
.history-tl-container ul.tl li::before {
    position: absolute;
    left: -16px;
    top: -4px;
    content: " ";
    border: 8px solid rgba(255, 255, 255, 0.74);
    border-radius: 500%;
    background: #fc6f2f;
    height: 30px;
    width: 30px;
    z-index: 1;
}

.history-tl-container ul.tl li.in-progress::before {
    position: absolute;
    left: -16px;
    top: -4px;
    content: " ";
    border: 8px solid rgba(255, 255, 255, 0.74);
    border-radius: 500%;
    background: #fc6f2f;
    height: 30px;
    width: 30px;
    animation: pulse-ring 2.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    z-index: 1;
}
.history-tl-container ul.tl li.in-progress::after {
    content: '';
    height: 38px;
    width: 38px;
    border-radius: 50%;
    left: -20px;
    border: 2px dashed #fc6f2f;
    top: -8px;
    position: absolute;
    animation-name: Rotate;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 0;
}
@keyframes Rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(.7);
    }
    80%, 100% {
      opacity: 1;
    }
  }
.history-tl-container ul.tl li.completed::before {
    position: absolute;
    left: -16px;
    top: -4px;
    content: "?";
    border: 8px solid #fc6f2f;
    border-radius: 500%;
    background: #fc6f2f;
    height: 30px;
    width: 30px;
    font-weight: bold;
    color: #fff;
    transition: all 500ms ease-in-out;
    line-height: 15px;
}
.history-tl-container ul.tl li.completed:hover::before{
    border-color: #fc6f2f;
}
.history-tl-container ul.tl li:hover::before{
    border-color:  #ffffff;
    transition: all 1000ms ease-in-out;
}

ul.tl li .item-detail{
    color:rgba(0,0,0,0.5);
    font-size:12px;
}
ul.tl li .timestamp{
    color: #8D8D8D;
    position: absolute;
    width:100px;
    left: -50%;
    text-align: right;
    font-size: 12px;
}
.r-img{
    width: 100%;
}

.feature-info.rodmp{
    display: flex;
    gap: 14px;
    opacity: 0.8;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 0.95rem;
    font-family: "Nunito Sans", sans-serif;
}
.btn.primary.gfa, .btn.mint.gfa {
	height: 46px;
	padding-left: 20px;
    width: auto!important;
    padding-right: 20px;
    font-weight: 400;
    text-decoration: none;
}

button.gfa {
    color: #fc6f2f;
    font-size: 0.95rem;
    font-weight: 500;
    margin-top: -8px;
    transition: all 100ms;
    transform: translateX(2px);
}
button.gfa img{
    transition: all 100ms;
}
button.gfa:hover img{
    transform: translateX(3px);
}

.btn-box{
    display: flex;
    gap: 15px;
}

.history-tl-container h3 {
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: rgba(255,255,255,0.9);
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    padding-right: 20px;
}
.history-tl-container h3 + p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: rgba(255,255,255,0.85);
    font-family: "Nunito Sans", sans-serif;
    line-height: 21px;
    padding-right: 20px;
   
}
.tl-item .feature-title {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 191px;
}


.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  background-color: #fff; 
  border: 1px solid #ddd; 
  cursor: pointer;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}

.item.control.cursor-pointer {
    color: #fff;
}

.custom-scrollbar {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    padding-top: 10px;
  }
  
 
  .custom-scrollbar:hover {
    scrollbar-color: #888888 #f0f0f0; 
  }
  
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 12px; 
    height: 10px; 
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
  }
  
  .custom-scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: #555; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent; 
  }
  .swipperTab.controls{
    padding-bottom: 10px;
  }
  
  .custom-row {
    max-width: 1200px;
    margin: 0 auto;  
  }

  .CircularProgressbar {
    width: 210px!important;
    height: 210px!important;
    vertical-align: middle;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 91px;
    margin-bottom: 80px;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: rgba(255,255,255,0.8)!important;
    font-size: 15px!important;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #fc6f2f!important;
}
.CircularProgressbar-background {
    fill:transparent!important;
  }


.buttons-container > .nav-item.dropdown{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    padding: 0 !important;
    color: #fff;
    background-color: transparent;
    border-radius: 50px;
    border: 1.5px solid rgba(255, 255, 255, 0.25);
    background-color: #fc6f2f;
    transition: 500ms;
    cursor: pointer;
}
.buttons-container > .nav-item.dropdown:hover{
    background-color: rgb(242, 88, 17);
}
.buttons-container #basic-nav-dropdown{
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
}

.buttons-container > .nav-item.dropdown .dropdown-toggle::after {
    position: absolute;
    right: -14px;
 }

.buttons-container > .nav-item.dropdown .user-circle-dropdown .user-circle {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.captchabox{
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-modal .modal-content{
    width: 600px;
    max-width: 100%;
}

.closeNav .svg-inline--fa{
    height: 1.5em;
}

.navbar-toggler-icon {
    width: 1.8em;
    height: 1.8em;
}

.section-head {
    font-family: "Nunito Sans", sans-serif;
}

.banner-section .slick-dots .banner-thumbs-slider-card .bottom {
    padding: 14px 20px 20px 20px!important;
}

.banner-section .slick-dots .banner-thumbs-slider-card .bottom .control .buttons .details-btn:hover{
    color: #333;
}

.fgt-btn{
    font-size: 0.85rem;
    font-weight: 600;
    color: rgba(0, 19, 83, 0.7);
    transition: 500ms;
}
.fgt-btn:hover{
    color: rgba(0, 19, 83, 0.9);
}
.sup-btn{
    width: 100%;
    margin-top: 13px;
    font-size: 0.85rem;
    font-weight: 600;
    color: rgba(0, 19, 83, 0.7);
    transition: 500ms;
    letter-spacing: -0.2px;
}
.sup-btn span{
    font-weight: 700;
}
.sup-btn:hover{
    color: rgba(0, 19, 83, 0.9);
}
.modal-fullscreen .modal-body{
    display: block;
}
.cousreDetails__subInfo {
    max-height: 360px;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 360px;
    background: #10121d!important;
    margin-top: 52px!important;
}
.lang .btn{
    font-size: 0.8rem;
}

.lang .btn.primary{
    background: #3a5080!important;
}

.lang .btn.primary:hover{
    background: #3a5080!important;
    border-color: #3a5080!important;
    color: #fff!important;
}

.modal.coursePopup .btn.outline {
    background: 0 0;
    color: rgba(255,255,255,0.9);
    border: 1px solid rgba(255,255,255,0.9);
}

.error-text{
    color: #842029!important;
    white-space: pre-line;
    font-size: 0.85rem;
    background: #f8d7da;
    padding: 5px 10px 6px 10px;
    border-radius: 3px;
    border:1px solid #f5c2c7;
    margin-top:3px;
}
.error-text a {
    font-size: .85rem;
    color: #333;
    display: block;
    font-weight: 600;
}
.profile-sec{
    border: 1px solid rgba(255,255,255,0.15);
    background-color: #040d12;
    border-radius: 10px;
}
.border-right{
    border-right: 1px solid rgba(255,255,255,0.15);
}
.profile-img{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    object-fit: contain;
    overflow: hidden;
    width: 150px;
    height: 150px;
    background-color: #ddd;
    margin-bottom: 20px;
}
.pimg-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    flex-direction: column;
}
.pimg-sec .form-group > h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 296px;
    color: rgba(255, 255, 255, 0.9);
}
.profile-nav .nav-item > .nav-link {
    color: #8D8D8D;
    padding-left: 20px;
    font-size: 1rem;
}
.profile-nav .nav-item > .nav-link.active {
    box-shadow: inset 5px 0px 0px 0px rgb(242, 88, 17);
    background: rgba(255,255,255,0.05);
    color: rgba(255, 255, 255, 0.7);
}
.bottom-border{
    border-bottom: 1px solid rgba(255,255,255,0.15);
}
.form-control.dark{
    background-color: #000;
    border-radius: 8px;
    color: rgba(255,255,255,0.8);
}
.form-control.dark::placeholder{
    background-color: #000;
    border-radius: 8px;
    color: rgba(255,255,255,0.5);
}
.form-control.dark:focus{
    background-color: #000;
    color: rgba(255,255,255,0.9);
}
.rhs-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
}
.profile-nav{
    min-height: 300px;
}

.dropdown-menu a.dropdown-item:hover {
    color: rgb(242, 88, 17);
}





.wrap {

    margin: 3em auto;
  }
  
  .card {
    background: #fff;
    border: 1px solid #eee;
    transition: all 0.35s ease;
  }
  .card:hover {
    border-color: #ddd;
    box-shadow: rgba(10, 145, 145, 0.1) 0 0 5em;
  }
  .card:hover figure {
    transform: scale(0.6) translateY(-1.4em);
  }
  .card:hover .card--title {
    transform: translateY(-5em);
  }
  .card:hover .card--desc {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-duration: 0.4s;
  }
  .card:hover .card--desc p {
    opacity: 1;
  }
  .card:hover .card--desc hr {
    transition-delay: 0.1s;
    transform: scale(1, 1);
  }
  .card:hover .card--social li {
    opacity: 1;
    transition-duration: 0.3s;
    transition-delay: 0.1s;
    transform: translate(0, 0) rotate(0deg);
  }
  .card:hover .card--btn a {
    background: #fbfbfb;
    border-color: #ddd;
  }
  .card:hover .card--btn .moreinfo {
    opacity: 0;
    transform: translateY(-50px);
  }
  .card:hover .card--btn .fullprof {
    opacity: 1;
    transform: translateY(0);
  }
  .card figure {
    position: relative;
    z-index: 299;
    width: 10em;
    height: 10em;
    margin: 0 auto 1em;
    overflow: hidden;
    padding: 4px;
    transform-origin: center top;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

  
  .card-liner {
    position: relative;
    overflow: hidden;
    padding: 2em;
  }
  
  .card--title {
    text-align: center;
    line-height: 1;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .card--title h3 {
    margin: 0 0 0.4em;
    font-size: 1.4em;
  }
  .card--title p {
    margin: 0 0 5em;
    color: #a5a5a5;
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 2.2px;
  }
  
  .card--desc {
    position: absolute;
    top: 53.5%;
    left: 9%;
    width: 82%;
    text-align: center;
    padding-bottom: 0.8em;
    visibility: hidden;
    opacity: 0;
    transform: translateY(3em);
    transition: all 0.02s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .card--desc p {
    margin: 0 0 0.5em;
    font-weight: 300;
    opacity: 0;
    transition: all 0.55s ease 0.1s;
  }
  .card--desc p span {
    text-decoration: line-through;
  }
  .card--desc hr {
    border: none;
    width: 3em;
    margin: 0 auto 0.75em;
    height: 3px;
    background: rgb(242, 88, 17);
    transform: scale(0, 1);
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0.05s;
  }
  
  .card--btn {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .card--btn a {
    position: relative;
    overflow: hidden;
    display: block;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8em;
    color: #a5a5a5;
    padding: 1.4em 2em;
    border-top: 1px solid #eee;
    letter-spacing: 0.5px;
    transition: all 0.4s ease;
  }
  .card--btn a .fullprof {
    display: block;
    opacity: 0;
    color: rgb(242, 88, 17);
    transform: translateY(50px);
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .card--btn a .moreinfo {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.4em;
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .card--btn a .moreinfo i {
    margin-right: 5px;
  }
  
  .card--social {
    position: absolute;
    top: 3em;
    left: 0;
    width: 100%;
  }
  .card--social ul {
    list-style: none;
    margin: 0;
    padding: 2em;
    position: relative;
  }
  .card--social li {
    display: block;
    text-align: center;
    position: absolute;
    top: 0;
    opacity: 0;
    transition: all 0.02s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .card--social li.twitter {
    left: 72%;
    transform: translate(-200%, 1em) scale(0.7);
  }
  .card--social li.linkedin {
    right: 72%;
    transform: translate(200%, 1em) scale(0.7);
  }
  .card--social a {
    line-height: 2.4;
    display: block;
    width: 2.3em;
    height: 2.3em;
    border-radius: 50%;
    border: 1px solid #ccc;
    background: #fbfbfb;
    color: #0a9191;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  .card--social a:hover {
    background: #eee;
  }
  
  .cred {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background: #fff;
    border-top: 1px solid #eee;
    text-decoration: none;
  }
  .cred a {
    display: block;
    padding: 1em;
    text-decoration: none;
    color: #ccc;
    font-weight: 300;
    font-size: 0.9em;
    letter-spacing: 0.5px;
  }
  .cred a i {
    margin-left: 10px;
  }

.collbx{
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative;
  }
  .collbx button{
     color: rgba(0, 19, 83, 0.6);
     font-size: 0.85rem; 
     font-weight:600;
     padding-left: 5px;
     display: flex;
     width: 100%;
     justify-content: space-between;
  }
  .collbx i.cricon{
    color: rgba(0, 19, 83, 0.6);
    font-size: 0.80rem; 
    padding-right: 5px;
    transition: all 100ms;
  }
  .collbx [aria-expanded="false"] i.cricon{
    rotate: 0deg;
  }
  .collbx [aria-expanded="true"] i.cricon{
    rotate: 90deg;
  }
  .modal.fade .modal-dialog {
    animation-name: SlideFromRight;
    animation-duration: 250ms;
    animation-iteration-count: 1;
    animation-timing-function:ease-in-out;
    transition: opacity 500ms linear;
 }
 @keyframes SlideFromRight {
    0% {
        transform: translate3d(36%, 0, 0);
        opacity: 0;
    }
    80%, 100% {
        transform: translate3d(10%, 0, 0);
        opacity: 1;
    }
  }
  .modal-dialog.modal-dialog-centered.modal-fullscreen video{
    background: #10121d;
    width: 100%!important;
    height: 100%!important;
  }
 
  .sbtn {

    background-image: linear-gradient(to right, #244bd6, #fc6f2f);
    color: White;
    border-radius: 100px;
    font-size: 1rem;
    padding: 5px 0px;
    position: relative;
    cursor: pointer;
    min-width: 4em;
    min-height: 2.2em;
}
  
.sbtn span {
    transition: all .5s ease;
    margin: 2px 6px;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    top: 50%;
    left: .45em;
    transform: translateY(-62%);
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    font-size: 1.15rem;
}
  
.sbtn .circlee {
    transition: all .5s ease;
    width: 1.7em;
    height: 1.7em;
    border-radius: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
  
.sbtn .textOff {
    left: calc(100% - 1.75em);
}
  
.sbtn .off {
	background-color: #fff;
	left: .20em;
}
  
.sbtn .on {
	background-color: #fff;
	left: calc(100% - 1.91em);
}
  .sbtnbx{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: sans-serif;
  }

  .cdim-text {
    font-size: 0.90rem;
    padding: 4px 15px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    color: #fff;
    width: 114px;
}
 .cdim-text.bc1{
    background-color: #E7BD2B;
 }
 .cdim-text.bc2{
    background-color: #34648A;
 }
 .cdim-text.bc3{
    background-color: #5E17EB;
 }
 .tagbx{
    margin-right: 20px;
 }
 .tagbx.Rent{
    background-color: #E7BD2B;
 }
 .tagbx.Download{
    background-color: #34648A;
 }
 .tagbx.Subscription{
    background-color: #5E17EB;
 }
.plan-sub{
    font-size: 0.90rem;
    opacity: 0.8;
}
.currency-hide{
    display: none;
}
.c-sign {
    color: rgba(102, 108, 128, 0.95);
    font-size: 1rem;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
    position: absolute;
    right: 129px;
    bottom: -10px;
}
.t-amount {
    color: rgba(102, 108, 128, 0.95);
    font-size: 1.2rem;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
    position: absolute;
    right: 0px;
    top: 2px;
    width: 192px;
}
.cart-footer {
    position: relative;
    display: flex;
    height: 30px;
}
.cart-header {
    position: relative;
    display: flex;
    height: 10px;
}
.pricebx{
    display: flex;
    gap: 10px;
    justify-content: center;
}
.off-text{
    position: relative;
    top: -2px;
}

.category-box .category {
    padding: 4px 0px;
    color: #403a58;
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.15px;
    text-transform: uppercase;
    font-family: "Nunito Sans", sans-serif;
}
.category-box {
    display: flex;
    gap: 22px;
    justify-content: space-between;
}
.category-box .rate{
    gap: 1px;
}
.react-tooltip-lite {
    z-index: 99999!important;
    background: #4C5986;
    background-image: linear-gradient(#4C5986, #232a40);
    color: rgba(255,255,255,0.9);
    border-radius: 8px;
    font-size: 0.85rem;
    text-align: center;
    padding: 3px 0px;
    font-family: "Nunito Sans", sans-serif;
}
.react-tooltip-lite-up-arrow{
    border-top-color: #232a40;
}
.modal-body-head {
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.modal-body-head a{
    color: rgba(252, 111, 47, 0.86);
}
.modal-body-head h3{
    font-size: 1.7rem;
    background: linear-gradient(85deg, #202124 0.9%, #202124 89.58%);
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 700;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
}
.modal-body-head p {
    color: rgba(0, 19, 83, 0.7);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}
.nav-top .buttons-container .circle{
    position: relative;
}
.cart-counter{
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    top: 2px;
    right: -9px;
    color: #000;
    font-size: 0.85rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0px;
    font-weight: bold;
    pointer-events: none;
}
.btn.primary.small{
    height: 40px;
    padding: 0px 20px;
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 600!important;
    margin-bottom: 0px;
}
.details-btn.small{
    height: 40px;
    padding: 0px 20px;
    margin-bottom: 10px;
    font-size: 1rem;
    background-color: rgba(0, 19, 83, 0.7);
    color:#fff;
    font-weight: 600!important;
    transition: 500ms;
    margin-bottom: 0px;
}
.details-btn.small:hover{
    background-color: transparent;
    color: rgba(0, 19, 83, 0.7);
}
.lsbtnbx{
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.lsbtnbx span{
    color: rgba(0, 19, 83, 0.7);
    font-size: 1rem;
    font-weight: 600;
}

.fixed-nav {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: all 1000ms;
  }

.fixed-nav .nav-top .logo img:first-child {
   height: 60px;
   transition: all 500ms;
}

.fixed-nav .nav-top .logo img:last-child {
    height: 60px;
    transition: all 500ms;
 }
 .fixed-nav .nav-top {
    height: 90px;
    transition: all 500ms;
}

.cur-btn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 52px;
    align-items: center;
    color: rgba(255,255,255,0.9);
    padding: 0px 21px;
    background-color: #000!important;
    border-left: 1px solid #1f2937;
    border-top: 1px solid #1f2937;
    border-top-left-radius: 4px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.cur-btn2 {
    display: flex;
    width: 100%;
    justify-content:center;
    align-items: center;
    height: 52px;
    width: 52px;
    align-items: center;
    color: rgba(255,255,255,0.9);
    padding: 0px 21px;
    background-color: rgba(0,0,0,0.2)!important;
    border: 1px solid transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    transition: 500ms; 
}
.cur-btn2:hover {
    background-color: rgba(0,0,0,0.5)!important;
}
.cur-btn2 i{
    margin-top: 22px;
    display: inline-block;
}
.cur-btn .clbtn i{
    display: flex;
    width: 100%;
    justify-content:center;
    height:30px;
    width: 30px;
    border-radius: 50%;
    align-items: center;
    border: 1px solid #1f2937;
}
.cur-btn .clbtn :hover{
    background-color: #1f2937;
}

.accordion-item {
    color: #fff!important;
    background-color: #10121d!important;
    border-color: #1f2937!important;
}
.accordion-button {
    background-color: #10121d!important;
    color: rgba(255,255,255,0.9);
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.accordion-item .accordion-header .accordion-button {
    font-weight: 500;
}
.accordion-button::after{
    filter: invert(100%);
    opacity: 0.35;
}
.accordion-button:not(.collapsed) {
    color: rgba(255,255,255,0.7);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: none;
}

.curriculum-box.border{
    border-color:#1f2937!important ;
    cursor: pointer;
    transition: 300ms;
}

.curriculum-box:hover{
    background-color: #1c2740;
}

.curriculum-box.active{
    background-color: #1c2740;
    border-color: #fc6f2f!important;
}

.accordion-button {
    background-color: #10121d!important;
    color: rgba(255,255,255,0.9);
}
.modal.modal.coursePopup .modal-body .btn-close {
    color: #fff;
    box-shadow:inset 0px 0px 0px 1px rgba(255,255,255,0.3)!important;
}
.svg-inline--fa.fa-star.text-blue{
    color: #F7AA33;
}
.left-section{
    position: relative;
}
.main-title {
    text-align: center;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.3rem;
    letter-spacing: -0.9px;
    color: var(--TExt-color, #001353);
    margin-top: 10px;
}
.sclist{
    min-height: 143px;
}
.sclist li {
    display: flex;
    color: rgba(0, 19, 83, 0.85);
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.3px;
    font-family: 'Nunito Sans';
    line-height: 20px;
    align-items: center;
    margin-bottom: 10px;
}
.sclist svg{
    width:18px;
    margin-right: 10px;
}

.subscription .row.g-4 > .col-lg-3:first-child .subscription__card{
    background-image: linear-gradient(to bottom right, #fec8e0, #fff);
    transition: 500ms;
}
.subscription .row.g-4 > .col-lg-3:nth-of-type(2) .subscription__card{
    background-image: linear-gradient(to bottom right, #e7c5fd, #fff);
    transition: 500ms;
}
.subscription .row.g-4 > .col-lg-3:nth-of-type(3) .subscription__card{
    background-image: linear-gradient(to bottom right, #d1d9fd, #fff);
    transition: 500ms;
}

.subscription .row.g-4 > .col-lg-3:last-child .subscription__card{
    background: linear-gradient(331deg, rgba(0, 13, 59, 0.5) 10.96%, rgba(4, 25, 106, 0.5) 98.75%), linear-gradient(5deg, rgba(0, 16, 70, 0.5) 23.53%, rgba(6, 21, 78, 0.5) 95.85%);
    color: #fff;
}
.subscription .row.g-4 > .col-lg-3:last-child .subscription__card .main-title{
    color: #CCCBFF;
}
.subscription .row.g-4 > .col-lg-3:last-child .subscription__card .price__before {
    color: #CCCBFF;
    text-decoration-color: #fff;
}
.subscription .row.g-4 > .col-lg-3:last-child .subscription__card .price__before p {
    padding-left: 3px;
    padding-right: 3px;
}
.subscription .row.g-4 > .col-lg-3.col-md-6.col-12:nth-of-type(1) .subscription__card .price__now .usa, .subscription .row.g-4 > .col-lg-3.col-md-6.col-12:nth-of-type(1) .subscription__card .price__now .india {
    color: #f72585;
}
.subscription .row.g-4 > .col-lg-3.col-md-6.col-12:nth-of-type(2) .subscription__card .price__now .usa, .subscription .row.g-4 > .col-lg-3.col-md-6.col-12:nth-of-type(2) .subscription__card .price__now .india {
    color: #7209b7;
}
.subscription .row.g-4 > .col-lg-3.col-md-6.col-12:nth-of-type(3) .subscription__card .price__now .usa, .subscription .row.g-4 > .col-lg-3.col-md-6.col-12:nth-of-type(3) .subscription__card .price__now .india {
    color: #4361ee;
}

.subscription .row.g-4 > .col-lg-3.col-md-6.col-12:nth-of-type(1) .subscription__card p.main-title{
    color: #f72585;
}
.subscription .row.g-4 > .col-lg-3.col-md-6.col-12:nth-of-type(2) .subscription__card p.main-title{
    color: #7209b7;
}
.subscription .row.g-4 > .col-lg-3.col-md-6.col-12:nth-of-type(3) .subscription__card p.main-title {
    color: #4361ee;
}

.subscription .row.g-4 > .col-lg-3:last-child .subscription__card h3 {
    color: #ebebfb;
}
.subscription .row.g-4 > .col-lg-3:last-child .subscription__card .sclist li {
    color: #ebebfb;
}

.subscription .row.g-4 > .col-lg-3:last-child .subscription__card .price__now .usa, .subscription .row.g-4 > .col-lg-3:last-child .subscription__card .price__now .india {
    color: #fff;
}
.subscription .row.g-4 > .col-lg-3 .subscription__card .btn.green, .subscription .row.g-4 > .col-lg-3 .subscription__card .btn.mint{
    height: 60px;
}

.sub-btn {
    border: 1px solid #4361ee;
    font-size: 0.9rem;
    height: 42px;
    color: #4361ee;
    font-weight: 600;
    transition: 300ms;
}
.subscription .row.g-4 > .col-lg-3:last-child .subscription__card .sub-btn{
    border: 1px solid #4361ee;
    background-color:#4361ee;
    color: #fff;
}
.sub-btn:hover {
    border: 1px solid #4361ee;
    background-color:#4361ee;
    color: #fff;
}
.subscription__card{
    position: relative;
}

.subscription__card:hover{
    --borderWidth: 5px;
    background: #fff;
    border-radius: var(--borderWidth);
    transition: 500ms;
}
.subscription__card:before{
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #fff;
    overflow: hidden;
    z-index: -1;
    transition: 500ms;
}
.subscription .row.g-4 > .col-lg-3:first-child .subscription__card:before{
    background-image: linear-gradient(to bottom right, #fec8e0, #fff);
}
.subscription .row.g-4 > .col-lg-3:nth-of-type(2) .subscription__card:before{
    background-image: linear-gradient(to bottom right, #e7c5fd, #fff);
}
.subscription .row.g-4 > .col-lg-3:nth-of-type(3) .subscription__card:before{
    background-image: linear-gradient(to bottom right, #d1d9fd, #fff);
}
.subscription .row.g-4 > .col-lg-3:last-child .subscription__card:before{
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #0a1231;
    overflow: hidden;
    z-index: -1;
}

.subscription__card:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #fc6f2f, #fc6f2f, #fc6f2f,  #bcd600, #ffa000, #ff4e3d, #ff0092, #cd12eb);
    z-index: -2;
    animation: animatedgradient 2s ease alternate infinite;
    background-size: 300% 300%;
  }
  
  
  @keyframes animatedgradient {
    0%, 100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }

  .subscription .row.g-4 > .col-lg-3:nth-of-type(1) .subscription__card .sub-btn {
    border: 1px solid #f72585;
    background-color: #f72585;
    color: #fff;
    font-size: 1.3rem;
    height: 60px;
    border-radius: 50px
  }
  .subscription .row.g-4 > .col-lg-3:nth-of-type(1) .subscription__card .sub-btn:hover {
    border: 1px solid #ab1a5b;
    background-color: #ab1a5b;
    color: #fff;
  }
  .subscription .row.g-4 > .col-lg-3:nth-of-type(2) .subscription__card .sub-btn {
    border: 1px solid #7209b7;
    background-color: #7209b7;
    color: #fff;
    height: 60px;
    border-radius: 50px
  }
  .subscription .row.g-4 > .col-lg-3:nth-of-type(2) .subscription__card .sub-btn:hover {
    border: 1px solid #4b0779;
    background-color: #4b0779;
    color: #fff;

  }
  .subscription .row.g-4 > .col-lg-3:nth-of-type(3) .subscription__card .btn.green, .subscription .row.g-4 > .col-lg-3:nth-of-type(3) .subscription__card .btn.mint {
    border: 1px solid #4361ee;
    background-color: #4361ee;
    color: #fff;
  }
  .subscription .row.g-4 > .col-lg-3:nth-of-type(3) .subscription__card  .btn.green:hover, .subscription .row.g-4 > .col-lg-3:nth-of-type(3) .subscription__card  .btn.mint:hover {
    border: 1px solid #2c3f92;
    background-color: #2c3f92;
    color: #fff;
  }
  .subscription .row.g-4 > .col-lg-3:nth-of-type(4) .subscription__card .btn.green, .subscription .row.g-4 > .col-lg-3:nth-of-type(4) .subscription__card .btn.mint {
    border: 1px solid #ebebfb;
    background-color: #ebebfb;
    color:#001353 ;
  }
  .subscription .row.g-4 > .col-lg-3:nth-of-type(4) .subscription__card  .btn.green:hover, .subscription .row.g-4 > .col-lg-3:nth-of-type(4) .subscription__card  .btn.mint:hover {
    border: 1px solid #f1f1f7;
    background-color: #f1f1f7;
    color:#001353 ;
  }

  .subscription__card .buttons-container{
    opacity: 1;
  }




  .flip-container {
    perspective: 1000px;
    position: relative;
    width: 100%;
    height: 585px;
}

  .flip-card-inner {
    transition: transform 0.6s ease;
  }
  
  .flip-card {
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .flip-card.flipped {
    transform: rotateY(180deg);
  }
  
  .flip-card-inner {
    position: relative;
    transform-style: preserve-3d;
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    top:0px;
    bottom:0px;
    left: 0px;
    right: 0px;
    backface-visibility: hidden;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }

  .flip-container.flipped .flip-card-inner {
    transform: rotateY(180deg);
    /* Other necessary properties for transitions/animations */
  }


  .wrapper1 {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  
  .card1 {
    width: 420px;
    height: 340px;
    margin: 1em;
    perspective: 1500px;
  }
  .card1 .content1 {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
  }
  
  .more1 {
    display: none;
  }
  .more1:checked ~ .content1 {
    transform: rotateY(180deg);
  }
  
  .front1,
  .back1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    border-radius: 6px;
  }
  .front1 .inner1,
  .back1 .inner1 {
    height: 100%;
    display: grid;
    padding: 1.5em;
    transform: translateZ(80px) scale(0.94);
  }
  
  .front1 {
    background-color: #fff;
    background-size: cover;
    background-position: center center;
  }
  .front1:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 6px;
    backface-visibility: hidden;
    background: linear-gradient(40deg, rgba(67, 138, 243, 0.7), rgba(255, 242, 166, 0.7));
  }
  .front1 .inner1 {
    grid-template-rows: 5fr 1fr 1fr 2fr 1fr;
    justify-items: center;
  }
  .front1 h2 {
    grid-row: 2;
    margin-bottom: 0.3em;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    font-weight: 500;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  }
  .front1 .rating1 {
    grid-row: 3;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    display: flex;
    flex-flow: row nowrap;
  }
  .front1 .rating1 i {
    margin: 0 1px;
  }
  
  .back1 {
    transform: rotateY(180deg);
    background-color: #fff;
    border: 2px solid #f0f0f0;
  }
  .back1 .inner1 {
    grid-template-rows: 1fr 2fr 1fr 2fr 14fr 1fr 1fr;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 0.8em;
    justify-items: center;
  }
  .back1 .info1 {
    position: relative;
    display: flex;
    align-items: center;
    color: #355cc9;
    grid-row: 3;
  }
  .back1 .info:not(:first-of-type):before {
    content: "";
    position: absolute;
    left: -0.9em;
    height: 18px;
    width: 1px;
    background-color: #ccc;
  }
  .back1 .info1 span {
    font-size: 2em;
    font-weight: 700;
  }
  .back1 .info1 i {
    font-size: 1.2em;
  }
  .back1 .info1 i:before {
    background: linear-gradient(40deg, #355cc9, #438af3);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  .back1 .info1 .icon1 {
    margin-left: 0.3em;
  }
  .back1 .info1 .icon1 span {
    display: block;
    margin-top: -0.25em;
    font-size: 0.8em;
    font-weight: 600;
    white-space: nowrap;
  }
  .back1 .description1 {
    grid-row: 5;
    grid-column: 1/-1;
    font-size: 0.86em;
    border-radius: 5px;
    font-weight: 600;
    line-height: 1.4em;
    overflow: auto;
    color: #355cc9;
    padding-right: 10px;
  }
  .back1 .location1,
  .back1 .price1 {
    font-weight: 600;
    color: #355cc9;
    grid-row: 1;
    font-size: 0.86em;
  }
  .back1 .location1 {
    grid-column: 1/3;
    justify-self: left;
  }
  .back1 .price1 {
    grid-column: 3/-1;
    justify-self: right;
  }
  .back1 .button1 {
    grid-column: 1/-1;
    justify-self: center;
  }
  
  .button1 {
    grid-row: -1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    padding: 0 1.5em;
    height: 3em;
    line-height: 2.9em;
    min-width: 3em;
    background-color: transparent;
    border: solid 2px #fff;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    left: 50%;
    backface-visibility: hidden;
    transition: 0.3s ease-in-out;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .button1:hover {
    background-color: #fff;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    text-shadow: none;
    color: #355cc9;
  }
  .button1.return1 {
    line-height: 3em;
    color: #355cc9;
    border-color: #355cc9;
    text-shadow: none;
  }
  .button1.return1:hover {
    background-color: #355cc9;
    color: #fff;
    box-shadow: none;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #859ddf;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #355cc9;
  }
  .btn.google, .btn.facebook{
    font-size: 1rem;
  }
  .btn.off-text{
    font-size: 0.95rem;
  }
  .banner-thumbs-slider-card .details-btn i{
    padding-left:0.6rem;
  }

  








  .main-content {
    display: flex;
    flex-wrap: wrap;
    margin: 0em auto 0;
    max-width: 1000px;
    justify-content: space-around;
    align-items: center;
  }
  
  .palette__wrapper {
    flex: 1;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .palette__wrapper h4{
    margin-top: 15px;
    font-size: 1.4rem;
  }
  
  .palette {
    width: 100%;
    height: 300px;
    position: relative;
    display: inline-block;
    perspective: 800px;
  }
  .palette__cover {
    border-radius: 6px;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    z-index: 2;
    transition: 0.3s ease;
    transform-origin: top left;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
    font-size: 4.5rem;
    text-transform: uppercase;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
  }
  .palette__cover__border {
    width: 84%;
    height: 84%;
    border: 7px solid #fff;
    margin: 8%;
}
.palette__cover span {
    display: inline-block;
    position: absolute;
    bottom: -3.5px;
    left: 11px;
    padding: 0px 11px 0px 7px;
    max-width: 50%;
}
  .palette__cover__top {
    position: absolute;
    left: 0;
    width: calc(100% + 64px);
    margin-left: -32px;
    z-index: 4;
    border-radius: 3px 3px 4px 4px;
    opacity: 0;
  }
  .palette__base {
    background: #fff;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    border-radius: 8px;
    padding: 15px;
    color: #0c0e23;
  }
  .palette:hover .palette__cover {
    transform: rotateX(76.6deg);
  }
  .palette:hover .palette__cover__top {
    animation: startTransition 0.17s forwards 0.1s;
  }
  
  @keyframes startTransition {
    0% {
      opacity: 0;
      top: 200px;
      transform: scale(0.5);
      height: 0;
    }
    40% {
      opacity: 1;
    }
    100% {
      top: 22px;
      opacity: 1;
      transform: scale(1);
      height: 15px;
      box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.2);
    }
  }
  .palette__shade {
    border-radius: 4px;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.9);
  }
  
  .palette--one .palette__base{
    display: flex;
    flex-direction: column;
  }
  .palette--one .palette__base p, .palette--two .palette__base p, .palette--three .palette__base p, .palette--four .palette__base p, .palette--five .palette__base p, .palette--six .palette__base p, .palette--seven .palette__base p, .palette--eight .palette__base p, .palette--nine .palette__base p, .palette--ten .palette__base p {
    padding-top: 8px;
  }
   .palette--one .palette__cover {
    background:#0B194D url(../../../public/images/courses/html5.jpeg)no-repeat;
    background-size: 194px;
    background-position: 32px 32px;
   }
   
  .palette--one .palette__cover__top {
    background: #0B194D;
  }
  .palette--one .palette__cover span {
    max-width: 50%;
    background: #0B194D;
  }
  
  .palette--two .palette__base {
    display: flex;
    flex-direction: column;
  }
  .palette--two .palette__cover {
    background:#0B194D url(../../../public/images/courses/css-basics.jpeg)no-repeat;
    background-size: 194px;
    background-position: 32px 32px;
   }
  .palette--two .palette__cover__top {
    background: #0B194D;
  }
  .palette--two .palette__cover span {
    max-width: 62%;
    background: #0B194D;
  }
  
  .palette--three .palette__cover {
    background:#0B194D url(../../../public/images/courses/jquery-and-javascript.jpeg)no-repeat;
    background-size: 194px;
    background-position: 32px 32px;
   }
  .palette--three .palette__cover__top {
    background: #0B194D;
  }
  .palette--three .palette__cover span {
    max-width: 48%;
    background: #0B194D;
  }
  .palette--three .palette__base {
    display: flex;
    flex-direction: column;
  }
  
  .palette--four .palette__base {
    display: flex;
    flex-direction: column;
  }

  .palette--four .palette__cover {
    background:#0B194D url(../../../public/images/courses/advanced-jquery.jpeg)no-repeat;
    background-size: 194px;
    background-position: 32px 32px;
  }
  .palette--four .palette__cover__top {
    background: #0B194D;
  }
  .palette--four .palette__cover span {
    max-width: 59%;
    background: #0B194D;
  }


  .palette--five .palette__base {
    display: flex;
    flex-direction: column;
  }

  .palette--five .palette__cover {
    background:#0B194D url(../../../public/images/courses/master-react.jpeg)no-repeat;
    background-size: 194px;
    background-position: 32px 32px;
  }
  .palette--five .palette__cover__top {
    background: #0B194D;
  }
  .palette--five .palette__cover span {
    max-width: 59%;
    background: #0B194D;
  }


  .palette--six .palette__base {
    display: flex;
    flex-direction: column;
  }

  .palette--six .palette__cover {
    background:#0B194D url(../../../public/images/courses/vuejs.jpeg)no-repeat;
    background-size: 194px;
    background-position: 32px 32px;
  }
  .palette--six .palette__cover__top {
    background: #0B194D;
  }
  .palette--six .palette__cover span {
    max-width: 59%;
    background: #0B194D;
  }


  .palette--seven .palette__base {
    display: flex;
    flex-direction: column;
  }

  .palette--seven .palette__cover {
    background: #799170;
  }
  .palette--seven .palette__cover__top {
    background: #799170;
  }
  .palette--seven .palette__cover span {
    max-width: 59%;
    background: #799170;
  }


  .palette--eight .palette__base {
    display: flex;
    flex-direction: column;
  }

  .palette--eight .palette__cover {
    background: #ee8828;
  }
  .palette--eight .palette__cover__top {
    background: #ee8828;
  }
  .palette--eight .palette__cover span {
    max-width: 59%;
    background: #ee8828;
  }

  .palette--nine .palette__base {
    display: flex;
    flex-direction: column;
  }

  .palette--nine .palette__cover {
    background: #a8edcf;
  }
  .palette--nine .palette__cover__top {
    background: #a8edcf;
  }
  .palette--nine .palette__cover span {
    max-width: 59%;
    background: #a8edcf;
  }

  .palette--ten .palette__base {
    display: flex;
    flex-direction: column;
  }

  .palette--ten .palette__cover {
    background: #BF9B65;
  }
  .palette--ten .palette__cover__top {
    background: #BF9B65;
  }
  .palette--ten .palette__cover span {
    max-width: 59%;
    background: #BF9B65;
  }
  .palette__cover {
    opacity: 0.95;
}





.containerr{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
  }
  
  .containerr .card {
    position: relative;
    background-color: transparent;
    border-color: transparent;
    height: 265px;
    display: flex;
    justify-content: end;
    margin-bottom: 40px;
 }

  .containerr .card h6{
   font-weight: 700;
  }
  
  .containerr .card .face{
    width:100%;
    height: 200px;
    transition:.4s;
    
  }
  
  .containerr .card .face.face1{
    position: relative;
    background: #333;
    display: flex;
    justify-content: center;
    align-content:center;
    align-items: center;
    z-index: 2;
    transform: translateY(100px);
  }
  
  .containerr .card:hover .face.face1{
    transform: translateY(0);
    box-shadow: 0 0 0px 5px #fc6f2f;
   
     
  }
  
  
  .containerr .card .face.face1 .content{
    opacity: .9;
    transition:  0.5s;
    text-align: center;
    position: relative;
  }

  .containerr .card .face.face1 .content:before{
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height:150px;
    z-index: 0;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0.9) 80%);
  }

  .containerr .card .face.face1 .content p.course-title {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: block;
    z-index: 9;
    text-align: left;
    font-weight: 600;
    color: #fff;
    font-family: "Nunito Sans", sans-serif;
    padding: 0px 14px 5px 14px;
    font-size: 1.25rem;
    transition: 1500ms;
    letter-spacing: -0.5px;
    line-height: 29px;
}

.containerr .card .face.face1 .content p.course-title:before{
    content: '';
    position: absolute;
    display: block;
    height: 5px;
    bottom: -7px;
    width: 0px;
    background-color: #fc6f2f;
    transition: 800ms;
}
.containerr .card:hover .face.face1 .content p.course-title:before{
    content: '';
    height: 5px;
    width: 270px;
    bottom: -7px;
    background-color: #fc6f2f;
}
.containerr .card:hover .face.face1 .content p.course-title {
    transform: translateY(-7px);
}

  .containerr .card .face.face1 .content img{
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .containerr .card:hover .face.face1 .content{
    opacity: 1;
   
  }
  
  .containerr .card .face.face1 .content i{
    font-size: 3em;
    color: white;
    display: inline-block;
     
  }
  
  .containerr .card .face.face1 .content h3{
    font-size: 1em;
    color: white;
    text-align: center;
    
  
  }
  
  .containerr .card .face.face1 .content a{
     transition: .5s;
  }
  
  .containerr .card .face.face2 {
    position: relative;
    background: whitesmoke;
    display: flex;
    padding: 12px 14px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0,0,0,.8);
    color: rgba(0, 19, 83, 0.95);
    transform: translateY(-100px);
}
  
  .containerr .card:hover .face.face2{
      transform: translateY(0);
      z-index: 1;
  }
  
  .containerr .card .face.face2 .content p, a {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: #333;
    line-height: 20px;
    letter-spacing: -0.5px;
}
.containerr .card .face.face2 .content p{
    margin-top: 5px;
    margin-bottom: 13px!important;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
  
  .containerr .card .face.face2 .content a{
    text-decoration:none;
    color: black;
    box-sizing: border-box;
    outline : 1px dashed #333;
    padding: 10px;
    margin: 15px 0 0;
    display: inline-block;
  }
  
  .containerr .card .face.face2 .content a:hover{
    background: #333 ;
    color: whitesmoke; 
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
  }
  .roadmap-box .col-lg-4 .containerr .card .face.face1 .content:after {
    content: '';
    font-weight: 600;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 1.4rem;
    width: 36px;
    height: 34px;
    display: flex;
    z-index: 99;
    background: rgba(0,0,0,1);
    color: #fff;
    line-height: 88px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 50% 0px;
    font-family: "Nunito Sans", sans-serif;
    border-right:2px solid #fc6f2f;
    border-bottom: 2px solid #fc6f2f;
    transition: 400ms;
}
.roadmap-box .col-lg-4 .containerr .card:hover .face.face1 .content:after{
    background: #fc6f2f;
    color: #000;
}
.roadmap-box .containerr .card .face.face2 .content .feature-info.rodmp {
    display: flex;
    gap: 14px;
    opacity: 0.8;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 0.90rem;
    line-height: 18px;
    margin-top: 20px;
    font-family: "Nunito Sans", sans-serif;
    justify-content: space-between;
}
.containerr .card .face.face2 .content .feature-info.rodmp i{
    color: rgba(49, 58, 88, 0.9);
}
.roadmap-box .col-lg-4:nth-of-type(1) .containerr .card .face.face1 .content:after {
    content: '1';
}
.roadmap-box .col-lg-4:nth-of-type(2) .containerr .card .face.face1 .content:after {
    content: '2';
}
.roadmap-box .col-lg-4:nth-of-type(3) .containerr .card .face.face1 .content:after {
    content: '3';
}
.roadmap-box .col-lg-4:nth-of-type(4) .containerr .card .face.face1 .content:after {
    content: '4';
}
.roadmap-box .col-lg-4:nth-of-type(5) .containerr .card .face.face1 .content:after {
    content: '5';
}
.roadmap-box .col-lg-4:nth-of-type(6) .containerr .card .face.face1 .content:after {
    content: '6';
}
.roadmap-box .col-lg-4:nth-of-type(7) .containerr .card .face.face1 .content:after {
    content: '7';
}
.roadmap-box .col-lg-4:nth-of-type(8) .containerr .card .face.face1 .content:after {
    content: '8';
}
.roadmap-box .col-lg-4:nth-of-type(9) .containerr .card .face.face1 .content:after {
    content: '9';
}
.roadmap-box .col-lg-4:nth-of-type(10) .containerr .card .face.face1 .content:after {
    content: '10';
}
.roadmap-box .col-lg-4:nth-of-type(11) .containerr .card .face.face1 .content:after {
    content: '11';
}
.roadmap-box .col-lg-4:nth-of-type(12) .containerr .card .face.face1 .content:after {
    content: '12';
}
.roadmap-box .col-lg-4:nth-of-type(13) .containerr .card .face.face1 .content:after {
    content: '13';
}
.roadmap-box .col-lg-4:nth-of-type(14) .containerr .card .face.face1 .content:after {
    content: '14';
}
.roadmap-box .col-lg-4:nth-of-type(15) .containerr .card .face.face1 .content:after {
    content: '15';
}
.roadmap-box .col-lg-4:nth-of-type(16) .containerr .card .face.face1 .content:after {
    content: '16';
}
.roadmap-box .col-lg-4:nth-of-type(17) .containerr .card .face.face1 .content:after {
    content: '17';
}
.roadmap-box .col-lg-4:nth-of-type(18) .containerr .card .face.face1 .content:after {
    content: '18';
}
.roadmap-box .col-lg-4:nth-of-type(19) .containerr .card .face.face1 .content:after {
    content: '19';
}
.roadmap-box .col-lg-4:nth-of-type(20) .containerr .card .face.face1 .content:after {
    content: '20';
}
.roadmap-box .col-lg-4:nth-of-type(21) .containerr .card .face.face1 .content:after {
    content: '21';
}
.roadmap-box .col-lg-4:nth-of-type(22) .containerr .card .face.face1 .content:after {
    content: '22';
}
.roadmap-box .col-lg-4:nth-of-type(23) .containerr .card .face.face1 .content:after {
    content: '23';
}
.roadmap-box .col-lg-4:nth-of-type(24) .containerr .card .face.face1 .content:after {
    content: '24';
}
.roadmap-box .col-lg-4:nth-of-type(25) .containerr .card .face.face1 .content:after {
    content: '25';
}
.roadmap-box .col-lg-4:nth-of-type(26) .containerr .card .face.face1 .content:after {
    content: '26';
}
.roadmap-box .col-lg-4:nth-of-type(27) .containerr .card .face.face1 .content:after {
    content: '27';
}
.roadmap-box .col-lg-4:nth-of-type(28) .containerr .card .face.face1 .content:after {
    content: '28';
}
.roadmap-box .col-lg-4:nth-of-type(29) .containerr .card .face.face1 .content:after {
    content: '29';
}
.roadmap-box .col-lg-4:nth-of-type(30) .containerr .card .face.face1 .content:after {
    content: '30';
}
.rbbox{
    display: flex;
    justify-content: space-between;
}
.rbbox > .buttons{
	flex: 1;
	display: flex;
	justify-content: end;
    gap: 15px;
}
.rbbox button.buy.lp {
	width: 40px;
    height: 40px;
    padding: 5px 8px !important;
}
.rbbox .svg-inline--fa {
	height: 1.1em;
}
.rbbox .details-btn {
	height: 40px;
	font-size: 0.8rem;
}
.rbbox button{
    font-size: 0.9rem;
}
.rbbox .btn.primary {
    background: #fc6f2f;
    color: #fff;
    width: 110px;
    height: 35px;
    line-height: 27px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    transition: all 500ms;
}
.rbbox .btn.primary:hover {
    background: transparent;
    color: #fc6f2f;
    border-color: #fc6f2f;
}
.rbbox .btn.qview {
    width: 110px;
    height: 35px;
    line-height: 27px;
    text-align: left;
    justify-content: flex-start;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    gap: 0px;
}
.cfb-box{
    position: relative;
    overflow: hidden;
    transition: 1000ms;
    border:1px solid rgba(255, 255, 255, 0.15);
    background: rgba(0,0,0,0.2);
    border-radius: 20px;
}
.cfb-box.br46{
    border-radius: 46px;
}
.cfb-box:hover img{
    transform: scale(1.05);
}
.cfb-box:hover {
    background: rgba(0,0,0,0.95);
}
.cfb-box img{
    width: 100%;
    transition: 500ms;
}




@-webkit-keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 7));
    }
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 7));
    }
  }
  .logo-slider {
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100vw;
    margin-bottom: 15vh;
  }
  .logo-slider::before, .logo-slider::after {
    background: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }
  .logo-slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  .logo-slider::before {
    left: 0;
    top: 0;
  }
  .logo-slider .slide-track {
    -webkit-animation: scroll 50s linear infinite;
            animation: scroll 50s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .logo-slider .slide {
    height: 100px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-rate .buttons button:nth-of-type(2) button.buy img{
    width:23px;
}
.rbbox .buttons button:nth-of-type(2) button.buy.lp img{
    width:20px;
}

.section-top-head h2 {
    margin-top: 60px;
    color: rgba(255, 255, 255, 0.90); 
    margin-bottom: 30px;
    font-family: 'Patrick Hand', cursive;
    letter-spacing: 1px;
}
.section-top-head h2.text-left {
    text-align: left!important;
}

.section-top-head h2.pro{
    font-family: "Nunito Sans", sans-serif;
    margin-bottom: 15px;
}
.section-top-head p.pro{
    font-family: "Open Sans", sans-serif;
    margin-bottom: 70px;
}
.section-top-head p {
    margin-bottom: 40px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    font-family: 'Grandstander', cursive;
}
.f-cards{
    color: #fbfbfb;
    text-align: left;
    display: flex;
    background: linear-gradient(331deg, rgba(0, 13, 59, 0.5) 10.96%, rgba(4, 25, 106, 0.5) 98.75%), linear-gradient(5deg, rgba(0, 16, 70, 0.5) 23.53%, rgba(6, 21, 78, 0.5) 95.85%);
    padding: 47px;
    transition: 500ms;
}
.f-cards.column {
    flex-direction: column;
}
.f-cards.column figure img{
    min-width: 60px;
    margin-bottom: 40px;
}
.f-cards:hover figure img{
    transform: scale(1.2);
}
.f-cards.column:hover figure img{
    transform: scale(1.05);
}
.f-cards:hover  h4{
    color: #fc6f2f;
}
.f-cards > div:first-child{
   width: 100px;
   padding-right: 20px;
}
.f-cards > div:last-child{
    flex: 1;
    text-align: left!important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
 }
.f-cards > div:first-child img{
    width: 100%;
    transition: 500ms;
 }
 .f-cards h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-family: 'Patrick Hand', cursive;
    letter-spacing: 1.5px;
    text-align: left;
}
.f-cards p{
    font-family: 'Grandstander', cursive;
    color: rgba(255, 255, 255, 0.7); 
}

.f-cards h4.pro {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-family: "Nunito Sans", sans-serif;
    letter-spacing: -0.5px;
    text-align: left;
}

.mt-100{
    margin-top: 100px;
    border-radius: 30px;
    overflow: hidden;
}
.f-cards p.pro{
    font-family: "Open Sans", sans-serif;
    color: rgba(255, 255, 255, 0.7); 
}

ul.timeline {
	list-style: none;
	padding: 0;
	margin: 0;
	display: grid;
	grid-template-columns: repeat(3, 220px);
	justify-content: center;
}
ul.timeline li {
	grid-column: span 2;
	text-align: center;
	border-radius: 65px;
	color: #fff;
	font-size: 25px;
	line-height: 1.5em;
    font-family: 'Patrick Hand', cursive;
    letter-spacing: 0.5px;
	position: relative;
	padding: 20px;
	border: 20px solid #0000;
	background: linear-gradient(#2F395B 0 0) content-box,
		linear-gradient(var(--c, #fc6f2f) 0 0) padding-box,
		linear-gradient(var(--d, 90deg), #0000 75%, #ffffff 0) border-box;
}
ul.timeline li span {
    padding: 0px 25px;
    text-align: left;
    display: flex;
}
ul.timeline li a{
    color: #fff;
}
ul.timeline li a span.title-cf {
    font-size: 25px;
    margin-bottom: 17px;
}
ul.timeline li:nth-child(even) {
	grid-column-end: -1;
	--d: -90deg;
	--c: #000000;
}
ul.timeline li:not(:last-child) {
	margin-bottom: -20px;
}
ul.timeline li:before,
ul.timeline li:after {
	content: "";
	display: block;
	height: 0.6em;
}


ul.timeline section {
	position: absolute;
	font-size: 2rem;
	left: calc(100% + 50px);
	top: 50%;
	transform: translateY(-50%);
	white-space: nowrap;
    font-family: 'Grandstander', cursive;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
ul.timeline li:nth-child(even) a > section{
	left: calc(100% + 12px);
}
ul.timeline li:nth-child(even) time {
	left: auto;
	color: #000;
	right: calc(100% + 50px);
}
ul.timeline time:before {
	content: "";
	position: absolute;
	height: 10px;
	top: calc(50% - 5px);
	right: calc(100% + 5px);
	width: 65px;
	background: repeating-linear-gradient(-90deg, #fc6f2f 0 5px, #0000 0 10px) 50%/100%
		2px no-repeat;
}
ul.timeline li:nth-child(even) time:before {
	right: auto;
	left: calc(100% + 5px);
	background: repeating-linear-gradient(90deg, #000000 0 5px, #0000 0 10px) 50%/100%
		2px no-repeat;
}

ul.timeline ul.timeline h1,
ul.timeline p {
	display: none;
}

.para-cf{
    font-family: 'Grandstander', cursive;
    font-size: 1rem;
    line-height: 22px;
    margin-top:5px;
}

.cfr-box {
	background: 
		radial-gradient(farthest-side at top right, #ffffff 98%, #0000)
			calc(50% - 10px) 0/20px 20px no-repeat,
		radial-gradient(farthest-side at bottom right, #ffffff 98%, #0000)
			calc(50% - 10px) 100%/20px 20px no-repeat,
		linear-gradient(90deg, #fc6f2f 50%, #000000 0);
}
.cf-q{
    color: rgba(255, 255, 255, 0.90);   
    font-family: 'Patrick Hand', cursive;
    font-size: 1.8rem;
    margin-bottom: 12px;
}
.cf-a{
    color: rgba(255, 255, 255, 0.7);   
    font-family: 'Grandstander', cursive; 
    margin-bottom: 30px;
}
.faq-box{
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}
.dropdown-menu.show {
    display: block;
    padding: 3px 0px;
}
.dropdown-menu.show a {
    font-size: 1.1rem;
    position: relative;
    padding-bottom: 9px;
    border-bottom: 1px solid #eee;
    padding-top: 9px;
}
.dropdown-menu.show a:last-child{
    border-bottom:none;
}
.cardsbox > div:first-child > div:nth-of-type(1){
   overflow-x: hidden!important;
}
.cardsbox > div:first-child > div:nth-of-type(2){
    visibility: hidden!important;
 }

 .Toastify__toast-container--top-center{
    white-space: normal!important;
    line-height: 19px!important;
}
p.pc-img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #fc6f2f;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 3rem;
    margin-bottom: 20px;
}
#contact {
    background:#020c30 url(../../../public/images/footer.svg)no-repeat top left;
    background-size: cover;
    border-bottom: 1px solid #ffffff26;
    position: relative;
    overflow: hidden;
}









.main-timeline {
    padding: 20px 0;
    position: relative;
    max-width: 900px;
    margin: 0px auto;
}
  
  .main-timeline::before,
  .main-timeline::after {
    content: "";
    height: 40px;
    width: 40px;
    background-color: #e7e7e7;
    border-radius: 50%;
    border: 10px solid #303334;
    transform: translatex(-50%);
    position: absolute;
    left: 50%;
    top: -15px;
    z-index: 2;
  }
  
  .main-timeline::after {
    top: auto;
    bottom: 15px;
  }
  
  .main-timeline .timeline {
    padding: 35px 0;
    margin-top: -30px;
    position: relative;
    z-index: 1;
  }
  
  .main-timeline .timeline::before,
  .main-timeline .timeline::after {
    content: "";
    height: 100%;
    width: 50%;
    border-radius: 161px 0 0 161px;
    border: 15px solid #46b2bc;
    border-right: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  
  .main-timeline .timeline::after {
    height: calc(100% - 30px);
    width: calc(50% - 12px);
    border-color: #65c7d0;
    left: 12px;
    top: 15px;
  }
  
  .main-timeline .timeline-content {
    display: inline-block;
  }
  
  .main-timeline .timeline-content:hover {
    text-decoration: none;
  }
  
  .main-timeline .timeline-year {
    color: #65c7d0;
    font-size: 50px;
    font-weight: 600;
    display: inline-block;
    transform: translatey(-50%);
    position: absolute;
    top: 50%;
    left: 14%;
    font-family: 'Patrick Hand', cursive;
}
  
  .main-timeline .timeline-icon {
      color: #65c7d0;
      font-size: 70px;
      display: inline-block;
      transform: translateY(-50%);
      position: absolute;
      left: 34%;
      top: 50%;
  }
  
  .main-timeline .content {
    color: #909090;
    width: 68%;
    padding: 20px;
    display: block;
    margin-left: auto;
}
  
  .main-timeline .title {
    color: #65c7d0;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 5px 0;
    letter-spacing: 1px;
    font-family: 'Patrick Hand', cursive;
  }
  
  .main-timeline .description {
    font-size: 16px;
    margin: 0;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Grandstander', cursive;
  }
  
  .main-timeline .timeline:nth-child(even)::before {
    left: auto;
    right: 0;
    border-radius: 0 110px 110px 0;
    border: 15px solid red;
    border-left: none;
  }
  
  .main-timeline .timeline:nth-child(even)::after {
    left: auto;
    right: 12px;
    border-radius: 0 100px 100px 0;
    border: 15px solid green;
    border-left: none;
  }
  
  .main-timeline .timeline:nth-child(even) .content {
    float: left;
  }
  
  .main-timeline .timeline:nth-child(even) .timeline-year {
    left: auto;
    right: 19%;
}
  
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    left: auto;
    right: 32%;
  }
  
  .main-timeline .timeline:nth-child(5n+1)::before {
    border-color: #46b2bc;
  }
  
  .main-timeline .timeline:nth-child(5n+1)::after {
    border-color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(5n+1) .timeline-icon {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(5n+1) .timeline-year {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(5n+1) .title {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(5n+2)::before {
    border-color: #ea3c14;
  }
  
  .main-timeline .timeline:nth-child(5n+2)::after {
    border-color: #EF5720;
  }
  
  .main-timeline .timeline:nth-child(5n+2) .timeline-icon {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(5n+2) .timeline-year {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(5n+2) .title {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(5n+3)::before {
    border-color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(5n+3)::after {
    border-color: #6CAF29;
  }
  
  .main-timeline .timeline:nth-child(5n+3) .timeline-icon
  {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(5n+3) .timeline-year {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(5n+3) .title {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(5n+4)::before {
    border-color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(5n+4)::after {
    border-color: #FBB03B;
  }
  
  .main-timeline .timeline:nth-child(5n+4) .timeline-icon {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(5n+4) .timeline-year {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(5n+4) .title {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(5n+5)::before {
    border-color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(5n+5)::after {
    border-color: #0050A3;
  }
  
  .main-timeline .timeline:nth-child(5n+5) .timeline-icon {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(5n+5) .timeline-year {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(5n+5) .title {
    color: #0071BD;
  }
  
  @media screen and (max-width:1200px){
      .main-timeline .timeline:after{ border-radius: 88px 0 0 88px; }
      .main-timeline .timeline:nth-child(even):after{ border-radius: 0 88px 88px 0; }
  }
  @media screen and (max-width:767px){
      .main-timeline .timeline{ margin-top: -19px; }
      .main-timeline .timeline:before {
          border-radius: 50px 0 0 50px;
          border-width: 10px;
      }
      .main-timeline .timeline:after {
          height: calc(100% - 18px);
          width: calc(50% - 9px);
          border-radius: 43px 0 0 43px;
          border-width:10px;
          top: 9px;
          left: 9px;
      }
      .main-timeline .timeline:nth-child(even):before {
          border-radius: 0 50px 50px 0;
          border-width: 10px;
      }
      .main-timeline .timeline:nth-child(even):after {
          height: calc(100% - 18px);
          width: calc(50% - 9px);
          border-radius: 0 43px 43px 0;
          border-width: 10px;
          top: 9px;
          right: 9px;
      }
      .main-timeline .timeline-icon{ font-size: 60px; }
      .main-timeline .timeline-year{ font-size: 40px; }
  }
  @media screen and (max-width:479px){
      .main-timeline .timeline-icon{
          font-size: 50px;
          transform:translateY(0);
          top: 25%;
          left: 10%;
      }
      .main-timeline .timeline-year{
          font-size: 25px;
          transform:translateY(0);
          top: 65%;
          left: 9%;
      }
      .main-timeline .content{
          width: 68%;
          padding: 10px;
      }
      .main-timeline .title{ font-size: 18px; }
      .main-timeline .timeline:nth-child(even) .timeline-icon{
          right: 10%;
      }
      .main-timeline .timeline:nth-child(even) .timeline-year{
          right: 9%;
      }
  }


  .timeline1 {
    margin: 0px auto;
    padding: 20px;
    max-width: 560px;
  }
  
  .timeline1 > .outer .card2 {
    position: relative;
    max-width: 600px;
  }
  
  .timeline1 > .outer .card2:nth-child(odd) {
    padding: 36px 0 36px 36px;
  }
  .timeline1 > .outer .card2:nth-child(even) {
    padding: 36px 36px 36px 0;
  }
  .timeline1 > .outer .card2::before {
    content: "";
    position: absolute;
    width: 50%;
    border: solid #fc6f2f;
  }
  
  .timeline1 > .outer .card2:nth-child(odd)::before {
    left: 0px;
    top: -4.5px;
    bottom: -4.5px;
    border-width: 5px 0 5px 5px;
    border-radius: 50px 0 0 50px;
  }


  /* css added 27 Jan 2025 */

.contact-form {
    justify-content: space-between;
}

.contactdetails {
    color: #fff;
    padding: 42px 35px;
    border-radius: 15px;
    text-align: center;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #fc6f2f, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    max-width: 300px;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.contactdetails i.fa-envelope {
    margin-bottom: 12px;
    display: inline-block;
}
.contact-form {
    max-width: 1114px;
}


  
  @media only screen and (max-width: 400px) {
    .timeline1 > .outer .card2:nth-child(odd)::before {
      top: -5px;
      bottom: -5px;
    }
  }
  
  .timeline1 > .outer .card2:nth-child(even)::before {
    right: 0;
    top: 0;
    bottom: 0;
    border-width: 5px 5px 5px 0;
    border-radius: 0 50px 50px 0;
  }
  

  .timeline1 > .outer .card2:first-child::before {
    border-top: 0;
    border-top-left-radius: 0;
  }
  
 
  .timeline1 > .outer .card2:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
  }
  

  .timeline1 > .outer .card2:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
  }
  

  .timeline1 > .outer .card2 .info {
    display: flex;
    flex-direction: column;
    border:1px solid rgba(255,255,255,0.3);
    background: linear-gradient(331deg, rgba(0, 13, 59, 0.5) 10.96%, rgba(4, 25, 106, 0.5) 98.75%), linear-gradient(5deg, rgba(0, 16, 70, 0.5) 23.53%, rgba(6, 21, 78, 0.5) 95.85%);
    color: gray;
    border-radius: 10px;
    padding: 19px;
  }
  

  .timeline1 > .outer .card2 .title {
    color: #fc6f2f;
    position: relative;
    font-family: 'Patrick Hand', cursive;
  }
  

  .timeline1 > .outer .card2 .title::before {
    content: "";
    position: absolute;
    width: 46px;
    height: 46px;
    background-image: linear-gradient(rgba(37,61,147,1), rgba(11,20,55,1));
    border-radius: 999px;
    border: 3px solid #fc6f2f;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Patrick Hand', cursive;
    color: #fc6f2f;
  }
  .timeline1 > .outer .card2:first-child .title::before{
    content: "1";
  }
  .timeline1 > .outer .card2:nth-of-type(2) .title::before{
    content: "2";
  }
  .timeline1 > .outer .card2:nth-of-type(3) .title::before{
    content: "3";
  }
  .timeline1 > .outer .card2:nth-of-type(4) .title::before{
    content: "4";
  }
  .timeline1 > .outer .card2:nth-of-type(5) .title::before{
    content: "5";
  }
  .timeline1 > .outer .card2:nth-of-type(6) .title::before{
    content: "6";
  }
  .timeline1 > .outer .card2:nth-of-type(7) .title::before{
    content: "7";
  }
  .timeline1 > .outer .card2:nth-of-type(8) .title::before{
    content: "8";
  }
  .timeline1 > .outer .card2:nth-of-type(9) .title::before{
    content: "9";
  }
  .timeline1 > .outer .card2:nth-of-type(10) .title::before{
    content: "10";
  }
  

  .timeline1 > .outer .card2:nth-child(even) > .info > .title {
    text-align: right;
  }
  

  .timeline1 > .outer .card2:nth-child(odd) > .info > .title::before {
    left: -75px;
  }

  .timeline1 > .outer .card2:nth-child(even) > .info > .title::before {
    right: -75px;
  }

  .card2 p{
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Grandstander', cursive;
  }
  .pro-wb{
    background-color: white;
    height: 100%;
    padding: 50px;
    border-radius: 40px;
    position: relative;
    z-index: 1;
    margin-right: -40px;
    overflow: hidden;
}
.profigure{
    height: 100%;
}
.proimg{
    width: 100%;
    position: relative;
    object-fit: cover;
    height: 100%;
    border-radius: 0px 40px 40px 0px;
}
.proimg.only{
    border-radius: 40px;
}
.pro-wb h4{
    font-size: 2.2rem;
    margin-bottom: 15px;
    font-family: "Nunito Sans", sans-serif;
    letter-spacing: -1.3px;
    font-weight: 600!important;
    max-width: 520px;
    text-align: left;
}
.pro-wb h4 span{
    color: #fc6f2f;
}
.pro-wb h6{
    font-family: "Nunito Sans", sans-serif;
    letter-spacing: -0.5px;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 1.2rem;
    max-width: 470px;
}
.pro-wb ul{
    font-family: "Open Sans", sans-serif;
    margin:0px;
    padding: 0px;
    font-weight: 500;
    opacity: 0.9;
    line-height: 30px;
    margin-bottom: 20px;
}
.pro-wb ul li{
    background: transparent url(../../../public/images/star-icon.svg)no-repeat top left;
    padding-left: 35px;
}

.mcc{
    border-radius: 18px;
    overflow: hidden;
    background-color: #fff;
    min-height: 330px;
    cursor: pointer;
    transition: 0.3s;
}
.mcc:hover .category{
    color: #fc6f2f;
}

.mcci{
    width: 100%;
    border-radius: 18px;
}
.mcctt {
    font-size: 1.3rem;
    min-height: 58.22px;
    letter-spacing: -0.5px;
    font-weight: 700!important;
    color: #001353;
    margin-top: 10px;
}
.mccttbox{
    padding: 18px 30px;
}
.mcimx{
    position: relative;
}
.mcimx .plybtn{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    margin-top: -30px;
    left: 50%;
    margin-left: -30px;
    top:50%;
    opacity: 0;
    background: rgba(0,0,0,0.3) url(../../../public/images/play-icon.svg)no-repeat center center;
    transition: all 0.3s;
    box-shadow: 0px 0px 0px 1px rgba(255,255,255,0.3);
}
.mcc:hover .plybtn{
    opacity: 1;
}
.col-lg-4.col-md-4.col-12.f-cards.column{
    background: none;
    padding: 15px;
    cursor: pointer;
}
.col-lg-4.col-md-4.col-12.f-cards.column p.mb-0.pro{
   color: rgba(0, 19, 83, 0.85)!important;
   line-height: 30px;
}
.col-lg-4.col-md-4.col-12.f-cards.column p.mb-0.pro.bullet{
    list-style-type: disc;
    display: list-item;
    margin-left: 18px;
 }
.col-lg-4.col-md-4.col-12.f-cards.column .bgback1{
    background-image: linear-gradient(to bottom right, #fec8e0, #fff);
    display: flex;
    width: 100%;
    padding: 30px;
    transition: 500ms;
}
.col-lg-4.col-md-4.col-12.f-cards.column .bgback1:hover, .col-lg-4.col-md-4.col-12.f-cards.column .bgback2:hover, .col-lg-4.col-md-4.col-12.f-cards.column .bgback3:hover{
    transform: scale(1.15);

}
.bgback1 h4{
    color: #f72585;
}
.col-lg-4.col-md-4.col-12.f-cards.column .bgback2{
    background-image: linear-gradient(to bottom right, #e7c5fd, #fff);
    display: flex;
    width: 100%;
    padding: 30px;
    transition: 500ms;
}
.bgback2 h4{
    color: #7209b7;
}
.col-lg-4.col-md-4.col-12.f-cards.column .bgback3{
    background-image: linear-gradient(to bottom right, #d1d9fd, #fff);
    display: flex;
    width: 100%;
    padding: 30px;
    transition: 500ms;
}
.bgback3 h4{
    color: #4361ee;
}
.pptitle {
	font-size: 21px;
	font-weight: 400;
	margin-top: 25px;
	letter-spacing: -0.5px;
}
.row.g-4 > .col-12.professionals:first-child .subscription__card, .row.g-4 > .col-12.professionals:first-child .subscription__card:before {
	background-image: linear-gradient(to bottom right, #fec8e0, #fff);
	transition: 500ms;
}
.row.g-4 > .col-12.professionals:nth-of-type(2) .subscription__card, .row.g-4 > .col-12.professionals:nth-of-type(2) .subscription__card:before {
	background-image: linear-gradient(to bottom right, #e7c5fd, #fff);
}
.row.g-4 > .col-12.professionals:nth-of-type(3) .subscription__card, .row.g-4 > .col-12.professionals:nth-of-type(3) .subscription__card:before {
	background-image: linear-gradient(to bottom right, #d1d9fd, #fff);
}


.row.g-4 > .col-12.professionals .subscription__card img{
    width: 60px;
    margin-bottom: 10px;
    margin-top: 20px;
}
.row.g-4 > .col-12.professionals .subscription__card p.normal {
	color: rgba(0, 19, 83, 0.85);
	font-size: 1.1rem;
	font-style: normal;
	font-weight: 600;
	line-height: 148%;
	letter-spacing: -0.3px;
	font-family: 'Nunito Sans';
	min-height: 200px;
}
.row.g-4 > .col-12.professionals:first-child .subscription__card h4.section-head.pro {
	color: #f72585;
    font-size: 1.8rem;
    margin-bottom: 30px;
}
.row.g-4 > .col-12.professionals:nth-of-type(2) .subscription__card h4.section-head.pro {
	color: #7209b7;
    font-size: 2rem;
    margin-bottom: 30px;
}
.row.g-4 > .col-12.professionals:nth-of-type(3) .subscription__card h4.section-head.pro {
	color: #4361ee;
    font-size: 2rem;
    margin-bottom: 30px;
}
.row.g-4 > .col-12.professionals .subscription__card .sclist li {
	font-size: 1rem;
    font-weight: 600;
}
.row.g-4 > .col-12.professionals:first-child .subscription__card p.pptitle{
    color: #f72585;
}
.row.g-4 > .col-12.professionals:nth-of-type(2) .subscription__card p.pptitle{
    color: #7209b7;
}
.row.g-4 > .col-12.professionals:nth-of-type(3) .subscription__card p.pptitle{
    color: #4361ee;
}

.buy.lp.rent{
    background-color: #7441a3!Important;
    transition: all 0.3s ease-in-out;
}

.buy.lp.rent:hover{
    background-color: #39135a!Important;
    border: 1px solid transparent;
}
.alert.custom {
    position: relative;
    padding-right: 50px;
}
.alert.custom div.alerttext{
    text-align: left;
}
.alert.custom button.closealert {
	position: absolute;
	right: 0;
	z-index: 2;
	width: 50px;
	height: 40px;
	margin-top: -20px;
	top: 50%;
	font-size: 26px;
}
.faq-item{
    color: #fff;
    text-align: left;
}
.faqbox{
    background-color: #1C2135;
    padding: 16px 26px;
    border-radius: 10px;
    margin-top: 100px;
}
.faq-question{
    font-weight: 600;
    font-size: 18px;
    min-height: 70px;
    border-bottom: 1px solid #3D4152;
    display: flex;
    align-items: center;
    color: #E2E3E6;
    padding-right: 50px;
    cursor: pointer;
    position: relative;
    font-family: 'Nunito Sans';
    transition: 200ms;
}
.faq-question .toggle-icon{
    position: absolute;
    width: 50px;
    height: 60px;
    top:50%;
    margin-top: -30px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
}
.faq-question.expanded{
    border-bottom-color: transparent;
}
.faqbox .faq-item:last-child .faq-question{
    border-color: transparent;
}
.faqbox .faq-item:last-child .faq-answer{
    border-color: transparent;
    padding-bottom: 10px;
}
.faq-answer{
    border-bottom: 1px solid #3D4152;
    padding-bottom: 30px;
    color: #AEB1C4;
    line-height: 30px;
}
.faq-answer a{
    color: #fc6f2f;
    text-decoration: underline;
}
.faq-title{
    color: #fff;
    text-align: left;
}


#mycourse > .cardsbox > div:nth-of-type(3) > div:nth-of-type(1){
    overflow-x: hidden!important;
 }

 #mycourse .feature-list .feature .feature__body .feature-info{
    display:none;
 }

 #mycourse .feature-list .feature .feature__body {
    min-height: 289px;
 }
 #mycourse .feature-list .flip-container {
    perspective: 1000px;
    position: relative;
    width: 100%;
    height: 516px;
 }

 #mycourse .section-head {
    margin-bottom: 50px;
}

.oint-link {
    width: 70px;
    background: #169d46;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e8e8e8;
    border-radius: 25px;
    gap: 7px;
    height: 45px;
    transition: 300ms;
}
.oint-link:hover {
    background: #0d6f30;
}

.oint-link.getcode{
    background: #355cc9;
}

.oint-link.getcode:hover{
    background: #2b4ba6;
}

.spcd-box{
    max-width: 700px;
    color:#F3F4F6 ;
}
.spcdc-box h3.curr-til {
    color: rgba(255,255,255,0.9);
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
    font-family: "Nunito Sans", sans-serif;
    margin-bottom: 20px;
}
.spcd-box h2.course-til {
    color: rgba(255,255,255,0.9);
    font-size: 2.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    font-family: "Nunito Sans", sans-serif;
    margin-bottom: 20px;
}
.spcd-box p.course-des {
    line-height: 35px;
}
.spcdc-box ol {
    color: #F3F4F6;
    font-weight: 600;
    border: 1px solid #374151;
    border-radius: 16px;
    padding: 20px 50px;
    max-height: 1105px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.spcdc-box ol li {
    padding: 13px 5px 15px 5px;
    border-bottom: 1px solid #374151;
}
.spcdc-box ol li:last-child{
    border-bottom: none;
}
.spcd-img{
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 14px;
    transition: 700ms;
    cursor: pointer;
}
.spcd-img:hover img{
   transform: scale(1.05);
}
.spcd-img img{
    border-radius: 14px;
    transition: 300ms;
}
.spcd-swbtn{
    background-color: #fc6f2f;
    margin-top: 20px;
    display: flex;
    width: 175px;
    height: 56px;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 46px;
    margin-right: auto;
    margin-left: auto;
    font-weight: 600;
    margin-bottom: 60px;
    gap: 7px;
    transition: 300ms;
}
.spcd-swbtn:hover{
    background-color: #e94c05;
}
.instr-pic {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
}
.text-base {
    font-size: 1rem;
    font-weight: 600;
    color:#F3F4F6 ;
}
.text-gray-700{
    font-size: .875rem;
    color: #bbb6b6;
    font-weight: 400;
}
.spcd-rating .far.fa-star {
    color: #F7AA33;
    gap: 2px;
    display: flex;
}
.wywl-box{
    border: 1px solid #374151;
    border-radius: 16px;
    margin-top: 30px;
    padding: 25px;
    margin-bottom: 30px;
}
.wywl-box ul {
    list-style:disc;
    margin-bottom: 0;
    margin-top: 15px;
}
.wywl-box ul li{
    line-height: 35px;
    margin-left: 20px;
}
.course-card {
    background: #141722;
    padding: 20px;
    min-height: 5px;
    display: block;
    color: #F3F4F6;
    border-radius: 12px;
    margin-bottom: 30px;
    border: 1px solid #263345;
    transition: 300ms;
}
.course-card:hover{
    background-color: #212841;
}
.course-card img{
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.course-card figure{
    height: 100%;
}
.course-card-title {
    font-family: "Nunito Sans", sans-serif;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    text-overflow: ellipsis;
}
.course-card-summary{
    margin-bottom: 20px;
    font-size: 14px;
    opacity: 0.7;
    margin-top: 5px;
}
.course-card-para{
    line-height: 25px;
    font-size: 15px;
}
ul.spcdc-list {
    list-style: none;
    margin-bottom: 0;
    max-height: 627px;
    overflow-x: hidden;
    overflow-y: scroll;
}
ul.spcdc-list li:last-child a.course-card{
    margin-bottom: 0px;
}
.banner-thumbs-slider-card .buttons > .buy{
    display: none;
}
.cousreDetails .right-section summary {
    display: list-item;
    cursor: pointer;
    font-size: 1.1rem;
    font-family: "Nunito Sans", sans-serif;
}
.cousreDetails .right-section summary strong, .cousreDetails .right-section  details strong,  .cousreDetails .right-section p>strong{
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    color: rgba(255,255,255,0.80);
    letter-spacing: -0.2px;
}
.cousreDetails .right-section summary + p, .cousreDetails .right-section  details{
   margin-bottom: 13px;
}

.tab-buttons.cftc{
    margin-bottom: 40px;
}

.tab-buttons.cftc button {
    height: 51px;
    font-weight: 600;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #19344d;
    margin-right: 14px;
    border-radius: 6px;
    color: #dddeea;
    border-top: 1px solid #19344d;
    border-left: 1px solid #19344d;
    border-right: 1px solid #19344d;
    border-bottom:5px solid #19344d;
    font-family: "Nunito Sans", sans-serif;
}
.tab-buttons.cftc button.active {
    border-color: #fc6f2f;
    color: #fc6f2f;
}
.blank-box {
    display: flex;
    background-image: linear-gradient(to right, rgba(0,0,0,0) , #fc6f2f, rgba(0,0,0,0));
    justify-content: center;
    flex-direction: column;
    min-height: 400px;
    align-items: center;
}
.blank-box h3 {
    margin-top: 60px;
    color: rgba(255, 255, 255, 0.90);
    margin-bottom: 20px;
    font-family: 'Patrick Hand', cursive;
    letter-spacing: 1px;
}
.blank-box h5 {
    font-family: 'Grandstander', cursive;
}

ul.timeline li.active{
    background: linear-gradient(#14168f 0 0) content-box,
    linear-gradient(var(--c, #fc6f2f) 0 0) padding-box,
    linear-gradient(var(--d, 90deg), #0000 75%, #ffffff 0) border-box;
}
ul.timeline li.active > a > section{
    background:#fc6f2f;
}















@media screen and (min-width: 2000px) {

    .swiper-wrapper {
        justify-content: center;
    }
  
  }

@media (max-width: 1200px) {
    /* .modal .modal-content {
        width: 90%;
        margin: auto;
    } */
}

@media only screen and (max-width: 991.98px) {
    .modal.cartPopup .modal-content .modal-body {
        padding: 33px 33px;
        gap: 20px;
    }

    .modal.cartPopup .modal-content .modal-body__info .course {
        flex-wrap: wrap;
        padding: 20px;
    }

    .modal.cartPopup .modal-content .modal-body__info .course__image {
        min-width: 150px;
    }

    .modal.cartPopup .modal-content .modal-body__info .course__info {
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
    }

    .modal.cartPopup .modal-content .modal-body__info .course__info .sub-info {
        flex-wrap: wrap;
    }

    .modal.cartPopup .modal-content .modal-body__info .course__info .control {
        width: 100%;
    }

    .modal.coursePopup .modal-body {
        padding: 70px 20px 50px;
    }

    .modal.coursePopup .modal-body .cousreDetails__video video {
        height: 220px;
    }

    .modal.coursePopup .modal-body .cousreDetails__mainInfo {
        gap: 10px;
    }

    .modal.coursePopup .modal-body .cousreDetails__mainInfo .lang,
    .modal.coursePopup .modal-body .cousreDetails__mainInfo .title {
        flex-wrap: wrap;
        gap: 10px !important;
    }

    .modal.coursePopup .modal-body .cousreDetails__mainInfo .buyContainer {
        gap: 10px;
    }
    #basic-nav-dropdown{
        text-align: left;
    }
    
}

.carousel>span {
    position: absolute;
    color: #fff;
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 140%;
    bottom: 430px;
    z-index: 99;
    left: 10%;
}

.carousel .carousel-indicators {
    margin-right: unset;
    width: 90%;
    overflow-x: auto;
    justify-content: flex-start;
    bottom: 100px;
    gap: 30px;
    margin-left: 10%;
}

.carousel .carousel-indicators:hover>div {
    filter: blur(5px);
}

.carousel .carousel-indicators::-webkit-scrollbar {
    display: none;
}

.carousel .carousel-indicators>div {
    background-color: transparent;
    text-indent: unset;
    position: relative;
    min-width: 465px;
    max-width: 100%;
    height: 295px;
    border-radius: 20px;
    border: 1.5px solid rgba(255, 255, 255, 0);
    box-shadow: 0 6.6313px 22.54642px 0 rgba(0, 62, 52, 0.2);
    overflow: hidden;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
    transition: 0.4s;
}

.carousel .carousel-indicators>div img {
    height: 100%;
    object-fit: cover;
}

.carousel .carousel-indicators>div:hover {
    filter: blur(0);
}

.carousel .carousel-indicators>div:hover .bottom {
    bottom: 0;
}

.carousel .carousel-indicators>div .top {
    position: absolute;
    padding: 16px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.carousel .carousel-indicators>div .top h4 {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
}

.carousel .carousel-indicators>div .top p {
    display: inline-flex;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #244bd6;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.carousel .carousel-indicators>div .bottom {
    position: absolute;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12.5px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    bottom: -68px;
    transition: 0.4s;
}

.carousel .carousel-indicators>div .bottom h3 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.carousel .carousel-indicators>div .bottom .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carousel .carousel-indicators>div .bottom .control .rate i,
.carousel .carousel-indicators>div .bottom .control .rate span {
    color: #fff;
}

.carousel .carousel-indicators>div .bottom .control .buttons {
    display: flex;
    gap: 15px;
}

.carousel .carousel-indicators>div .bottom .control .buttons .details-btn {
    color: #fff;
    border: 1.5px solid rgba(255, 255, 255, 0.25);
}

.carousel .carousel-inner .carousel-item {
    max-height: 716px;
}

.carousel .carousel-inner .carousel-item img {
    height: 716px;
    object-fit: cover;
}

.carousel .carousel-inner .carousel-item .video-container {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    /* display: none; */
}

.carousel .carousel-inner .carousel-item .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 50%;
    min-width: 50%;
    margin: auto;
}

.carousel .carousel-inner .carousel-item .carousel-caption {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 25px;
    top: 30%;
    bottom: unset;
    left: 10%;
    right: 10%;
    z-index: 9;
}

.carousel .carousel-inner .carousel-item .carousel-caption .caption-head {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.carousel .carousel-inner .carousel-item .carousel-caption .caption-head h3 {
    color: #fff;
    font-family: "Nunito Sans", sans-serif;
    font-size: 3.75rem;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: 0.6px;
}

.carousel .carousel-inner .carousel-item .carousel-caption .caption-head p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.carousel .carousel-inner .carousel-item .carousel-caption .caption-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.carousel .carousel-inner .carousel-item .carousel-caption .caption-tags .tag {
    display: flex;
    padding: 15px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    border: 1.5px solid rgba(255, 255, 255, 0.25);
    background: rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
}

.carousel .carousel-inner .carousel-item .carousel-caption .caption-tags .tag i {
    font-size: 1.68rem;
    background: linear-gradient(144deg, #ff9362 21.05%, #fc6f2f 82.43%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.carousel .carousel-inner .carousel-item .carousel-caption button {
    width: 147px;
}

@media only screen and (max-width: 1400px) {
    .subscription__card {
        padding: 30px 16px;
    }
    .ownPeace__card h3 {
        font-size: 1.7rem;
    }
    .ownPeace__card .icon img{
        max-width: 60px;
    }
    .feature__body {
        padding: 24px 20px;
        gap: 15px;
        min-height: 380px;
    }
    .flip-container {
        width: 100%;
        height: 625px;
    }
    .feature__head {
        height: 240px;
    }
    .feature__body .feature-info {
        gap: 3px;
    }
    .feature__body .feature-info>div span {
        font-size: 0.90rem;
    }
    .nav-top .buttons-container {
        gap: 10px;
    }
    .nav-top .nav-list .nav-link a {
        font-size: 1rem;
    }
    .nav-container .nav-link a {
        font-size: 1rem;
    }
    .nav-top form {
        position: relative;
        margin-left: 15px;
    }
    .btn {
        font-size: 0.95rem;
    }
    .f-cards {
        padding: 30px;
        flex-direction: column;
    }
    .f-cards h4 {
        margin-top: 20px;
    }
    .spcd-box {
        padding-right: 30px;
    }

}

@media only screen and (max-width: 1196px) {

    .subscription__card {
        padding: 30px 16px;
    }
    .ownPeace__card h3 {
        font-size: 1.7rem;
    }
    .ownPeace__card .icon img{
        max-width: 60px;
    }
    .feature__body {
        padding: 24px 20px;
        gap: 15px;
        min-height: 380px;
    }
    .flip-container {
        width: 100%;
        height: 475px;
    }
    .feature__head {
        height: 160px;
    }
    .feature__body .feature-info {
        gap: 3px;
    }
    .feature__body .feature-info>div span {
        font-size: 0.90rem;
    }
    .nav-top .buttons-container {
        gap: 10px;
    }
    .nav-top .nav-list .nav-link a {
        font-size: 1rem;
    }
    .nav-container .nav-link a {
        font-size: 1rem;
    }
    .nav-top form {
        position: relative;
        margin-left: 15px;
    }
    .btn {
        font-size: 0.95rem;
    }
    .category-box .category {
        padding: 6px 0px;
        font-size: 0.8rem;
    }
    .price .usa, .price .india  {
        font-size: 1.1rem;
    }
    .feature__body .feature-title h3 {
        font-size: 1.1rem;
        min-height: 49.22px;
    }
    .feature__body .feature-title p {
        font-size: 1rem;
    }
    .feature__body .feature-info {
        gap: 0px;
        flex-wrap: nowrap;
    }
    .feature__body .feature-info>div span {
        font-size: 0.8rem;
        line-height: 116%;
    }
    .feature__body {
        padding: 24px 14px;
        gap: 15px;
        min-height: 346px;
    }
    .details-btn {
        font-size: 0.75rem;
    }
    .row.g-4 .col-lg-3.col-md-6.col-12 {
        flex: 0 0 auto;
        width: 50%;
    }
    .lpath__card h6 {
        opacity: 0.6;
        max-width: 234px;
        margin-left: auto;
        margin-right: auto;
        line-height: 27px;
    }
    .ccbx {
        border-radius: 25% 25% 0px 0px;
    }
    .controls .control {
        font-size: 1rem;
        padding: 15px 26px;
    }
    .feature__body .feature-title h3 {
        max-height: 49px;
        overflow: hidden;
    }
    .f-cards h4 {
        font-size: 1.5rem;
        margin-bottom: 10px;
        font-family: 'Patrick Hand', cursive;
        letter-spacing: 1.5px;
        text-align: left;
        line-height: 32px;
    }
    .f-cards {
        padding: 30px;
        flex-direction: column;
    }
    .f-cards h4 {
        margin-top: 20px;
    }

    #basic-nav-dropdown::after {
        content: "";
        position: absolute;
        left: 36px;
        top: 50%;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.85);
    }
    #basic-nav-dropdown::after{
        background: none;
    }
    #basic-nav-dropdown::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }
    
    .nav-top .navContent .nav-list {
        gap: 18px;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .buttons-container>.nav-item.dropdown .dropdown-toggle:after {
        left: 46px!important;
    }
    .banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-head h3 {
        font-size: 3rem;
    }
    .dropdown-menu[data-bs-popper] {
        left: -95px;
        margin-top: var(--bs-dropdown-spacer);
        top: 100%;
    }
    .feature__body .feature-rate .buttons {
        gap: 6px;
    }
    .feature__body {
        padding: 15px 14px;
        gap: 15px;
        min-height: 313px;
    }
    .spcd-box {
        padding-right: 30px;
    }
    .course-card-title {
        margin-top: 12px;
    }
    .contact-form {
        flex-direction: column;
    }
    .contactdetails {
        margin-left: auto!important;
        margin-right: auto;
        margin-top: 27px;
        width: 435px;
    }
}

@media only screen and (width: 1024px) {
    .pro-wb {
        margin-right: -40px!important;
    }
    .proimg {
        border-radius: 0px 40px 40px 0px!important;
        top: 0px!important;
    }
    .f-cards p.pro {
        padding-bottom: 20px;
    }
    .f-cards.column figure img {
        max-width: 60px;
        margin-bottom: 4px;
    }
    .contact-form {
        flex-direction: column;
    }
    .contactdetails {
        margin-left: auto!important;
        margin-right: auto;
        margin-top: 27px;
        width: 435px;
    }
}

@media only screen and (max-width: 991.98px) {
    .banner-section .banner-carousel .banner-carousel-card .carousel-caption {
        top: 23%;
    }

    .banner-section .banner-carousel .banner-carousel-card .carousel-caption .caption-head h3 {
        font-size: 2.65rem;
    }

    .banner-section .banner-carousel .banner-carousel-card .carousel-caption.caption-head p {
        margin-bottom: 10px;
    }

    .banner-section .banner-carousel .banner-carousel-card .carousel-caption .caption-tags {
        margin-bottom: 10px;
    }
    .nav-bottom .nav-list {
        gap: 20px;
    }
    .containerr {
        width: 622px;
    }
    .f-cards {
        padding: 30px;
        flex-direction: column;
    }
    .f-cards h4 {
        margin-top: 20px;
    }
    .f-cards {
        padding: 18px;
        flex-direction: column;
    }
    .f-cards h4 {
        margin-top: 20px;
        max-width: 164px;
        line-height: 26px;
    }
    .nav-top .nav-list .nav-link a {
        font-size: 1.3rem;
    }
    .course-card-title {
        margin-top: 12px;
    }
    .col-lg-7.col-12.order-bottom{
        /* order: 2; */
    }
    .contact-form {
        flex-direction: column;
    }
    .contactdetails {
        margin-left: auto!important;
        margin-right: auto;
        margin-top: 27px;
        width: 435px;
    }
    
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {

    .containerr {
        width: 100%;
    }
    .containerr .card .face {
        width: 100%;
        height: 200px;
        transition: .4s;
    }
    .containerr .card .face.face1 .content p.course-title {
        font-size: 1rem;
        line-height: 25px;
    }
    .containerr .card:hover .face.face1 .content p.course-title:before {
        content: '';
        width: 211px;
    }
    .section-head {
        font-size: 2rem;
        line-height: 47px;
    }
    .row.g-4 .col-lg-3.col-md-6.col-12 {
        flex: 0 0 auto;
        width: 50%;
    }
    .flip-container {
        width: 100%;
        height: 470px;
    }
    .feature__head {
        height: 164px;
    }
    .category-box .category {
        padding: 8px 0px;
        font-size: 0.75rem;
    }
    .btn.off-text {
        font-size: 0.75rem;
    }
    .feature__body .feature-title h3 {
        font-size: 1.1rem;
        min-height: 58.22px;
    }
    .feature__body .feature-title p {
        font-size: 1rem;
    }
    .feature__body .feature-info {
        gap: 0px;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .feature__body .feature-info>div span {
        line-height: 18px;
    }
    .price .usa, .price .india {
        font-size: 1.1rem;
    }
    .contact-form .content h2 {
        font-size: 2.3rem;
    }
    .copyright__content ul li img {
        width: 25px;
        height: 35px;
        transition: 0.1s;
    }
    .footer__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 35px 0;
        gap: 13px;
    }
    .pb-115 {
        padding-bottom: 0px;
    }
    .lpath__card h6 {
        opacity: 0.6;
        max-width: 234px;
        margin-left: auto;
        margin-right: auto;
        line-height: 27px;
    }
    .ccbx {
        border-radius: 25% 25% 0px 0px;
    }
    .btn.primary.mw200 {
        min-width: 147px;
        display: inline-block;
        margin-top: 5px;
    }
    .subscription.pb-115{
        margin-bottom: 60px;
    }
    #ourcourse{
        margin-top: 65px;
    }
    .containerr .card .face.face2 .content p {
        height: 39px;
        overflow: hidden;
    }
    #contact{
        padding-bottom: 115px;
    }
    .subscription__head .price .price__now .usa {
        font-size: 2rem;
    }
    button.buy {
        font-size: 0.9rem;
    }
    .cousreDetails__mainInfo button.buy {
        font-size: 1rem;
    }
    .contact-form {
        flex-direction: column;
    }
    .contactdetails {
        margin-left: auto!important;
        margin-right: auto;
        margin-top: 27px;
        width: 435px;
    }

}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {

    .logo-slider {
        height: 90px;
        width: 100vw;
        margin-bottom: 7vh;
    }
    .main-title {
        font-size: 1.7rem;
    }
    .subscription__head .price .price__now .usa {
        font-size: 3.5rem;
    }
    .subscription__card h3 {
        font-size: 1.2rem;
    }
    .sclist li {
        font-size: 1.2rem;
    }
    .subscription__head .discount {
        font-size: 1rem;
    }
    .ownPeace__card h3 {
        font-size: 1.7rem;
        min-height: 62px;
    }
    .feature__head {
        height: 200px;
    }
    .flip-container {
        width: 100%;
        height: 506px;
    }
    .feature__body {
        padding: 24px 20px;
        gap: 15px;
        min-height: 308px;
    }
    .contact-form .content {
        flex-direction: column;
        gap: 5px;
        width: 438px;
    }
    .containerr {
        width: 100%;
    }
    .containerr .card .face {
        width: 100%;
        height: 200px;
        transition: .4s;
    }
    .containerr .card:hover .face.face1 .content p.course-title:before {
        content: '';
        height: 5px;
        width: 240px;
        bottom: -7px;
        background-color: #fc6f2f;
    }
    ul.timeline {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(3, 186px);
        justify-content: center;
    }
    .pro-wb {
        margin-right: 0px;
    }
    .proimg {
        border-radius: 0px 0px 40px 40px;
        top: -40px;
    }
    .f-cards p.pro {
        padding-bottom: 20px;
    }
    .f-cards.column figure img {
        max-width: 60px;
        margin-bottom: 4px;
    }
    button.buy {
        font-size: 1.15rem;
    }
    .accordion-button {
        font-size: 1.2rem;
    }
    .cousreDetails__subInfo {
        max-height: 200px!important;
        min-height: 200px!important;
    }
    .modal.coursePopup .modal-content .cousreDetails__mainInfo .buyContainer .buy {
        gap: 9px;
    }
    .modal.coursePopup .modal-content .cousreDetails__mainInfo .title h3 {
        font-size: 1.8rem;
    }
    .cousreDetails .right-section summary {
        font-size: 1.4rem;
    }
    .faq-answer>div{
        display: block!important;
    }
    .faq-answer>div img {
        width: 200px!important;
        margin-bottom: 10px;
    }
    .faqbox {
        margin-top: 10px;
    }
    .faq-question {
        font-size: 16px;
    }
    .faq-title {
        font-size: 22px;
    }
    .contact-form .content h2 {
        font-size: 2rem;
    }
    .contact-form .content span, .contact-form .content span a {
        font-size: 1.2rem;
    }
    .user.circle .svg-inline--fa{
        height: 1.2em;
    }
    .sbtn {
        font-size: 1.1rem;
    }
    #basic-nav-dropdown::after {
        content: "";
        border-top: .35em solid;
        border-right: .35em solid transparent;
        border-left: .35em solid transparent;
    }
    #basic-nav-dropdown::after {
        margin-left: -0.40em;
    }
    .cousreDetails .right-section summary + p, .cousreDetails .right-section  details {
        margin-bottom: 13px;
        font-size: 1.35rem;
        line-height: 30px;
    }
    .subscription__head .price .price__now .usa {
        font-size: 2rem;
    }
    button.buy {
        font-size: 0.9rem;
    }
    .order-top{
        margin-top: 55px!important;
    }
    .contact-form {
        flex-direction: column;
    }
    .contactdetails {
        margin-left: auto!important;
        margin-right: auto;
        margin-top: 27px;
        width: 435px;
    }
    

}

@media only screen and (max-width: 767px) {
    .fixed-nav .nav-top .logo img:last-child {
        height: 50px;
        transition: all 500ms;
    }
    .fixed-nav .nav-top .logo img:first-child {
        height: 50px;
        transition: all 500ms;
    }
    .nav-top .buttons-container .primary {
        padding: 0 17px;
    }
    .containerr {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
    .inputsContainer .row.gx-3{
        gap: 10px;
    }
    .contact-form .content {
        flex-direction: column;
        width: 320px;
        gap:0px;
    }
    .contact-form .content form {
        width: 320px;
    }
    .contact-form .content h2 {
        font-size: 2.5rem;
    }
    .col-lg-4.col-md-6.col-12.mix.tab-animation {
        flex: 0 0 auto;
        width: 50%;
    }
    .feature__head {
        height: 134px;
    }
    .price .usa, .price .india {
        font-size: 1rem;
    }
    .btn.off-text {
        font-size: 0.75rem;
    }
    .feature__body .feature-title h3 {
        font-size: 1rem;
        min-height: 40.22px;
    }
    .feature__body .feature-title p {
        max-height: 41px;
        overflow: hidden;
    }
    .feature__body {
        padding: 24px 20px 15px 20px;
        gap: 15px;
        min-height: 338px;
    }
    .lpath__card h6 {
        opacity: 0.6;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        line-height: 23px;
    }
    ul.timeline {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(3, 175px);
        justify-content: center;
    }
    .lpbb {
        color: #fff;
        font-size: 1.3rem;
        margin-top: 2px!important;
    }
    .logo-slider {
        width: calc(100vw - 30px);
    }
    
    .feature__body {
        padding: 15px 15px 15px 15px;
        gap: 15px;
        min-height: 338px;
    }
    .flip-container {
        width: 100%;
        height: 472px;
    }
    .pro-wb {
        margin-right: 0px;
    }
    .proimg {
        border-radius: 0px 0px 40px 40px;
        top: -40px;
    }
    .f-cards p.pro {
        padding-bottom: 20px;
    }
    .f-cards.column figure img {
        max-width: 60px;
        margin-bottom: 4px;
    }
    .sclist {
        min-height: 100px;
    }
    .details-btn {
        font-size: 1rem;
    }
    .spcd-box, .spcd-img, .spcdc-box {
        padding-left: 10px;
        padding-right: 10px;
    }
    .spcd-box, .spcd-img, .spcdc-box {
        margin-top: 30px;
    }
    .contact-form {
        flex-direction: column;
    }
    .contactdetails {
        margin-left: auto!important;
        margin-right: auto;
        margin-top: 27px;
        width: 350px;
    }
}



@media only screen and (max-width: 575.98px) {
 
    ul.timeline {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(3, 138px);
        justify-content: center;
    }
    .feature__body .feature-title p {
        max-height: 56px;
        overflow: hidden;
    }
    .col-lg-4.col-md-6.col-12.mix.tab-animation {
        flex: 0 0 auto;
        width: 100%;
    }

    .flip-container {
        perspective: 1000px;
        position: relative;
        width: calc(100% - 18px);
        height: 552px;
        margin-left: auto;
        margin-right: auto;
    }
    .feature__body .feature-title h3 {
        min-height: 20px;
    }
    .feature__body {
        display: flex;
        flex-direction: column;
        padding: 24px 30px;
        gap: 15px;
        min-height: 335px;
    }
    .cd-order1{
        order: 2;
    }
    .carousel>span {
        bottom: 320px;
    }

    .carousel .carousel-indicators {
        bottom: 80px;
    }

    .carousel .carousel-indicators>div {
        min-width: 325px;
        height: 205px;
    }

    .carousel .carousel-indicators>div .bottom {
        padding: 15px;
    }

    .carousel .carousel-indicators>div .bottom .buttons {
        gap: 7px;
    }

    .carousel .carousel-indicators>div .bottom .rate i {
        font-size: 14px;
    }

    .carousel .carousel-inner .carousel-item .carousel-caption .caption-tags .tag {
        padding: 10px 15px;
        font-size: 14px;
    }
    .nav-top .nav-container {
        width: 98%;
        margin-left: auto;
        margin-right: auto;
    }
    .nav-bottom .nav-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nav-bottom .nav-list .nav-link a {
        font-size: 13px;
        letter-spacing: -0.3px;
        font-weight: 600;
    }
    .nav-bottom .nav-list {
        flex-wrap: wrap;
        gap: 20px;
    }
    .banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-head p {
        font-size: 1.3rem!important;
    }
    .carousel-caption .btn {
        font-size: 1.2rem;
    }
    .subscription .section-head+p {
        font-size: 1.4rem;
    }
    .section-head {
        font-size: 2.2rem;
    }
    .subscription__card .buttons-container button {
        font-size: 1.2rem;
    }
    .subscription__card .buttons-container {
        gap: 14px;
        justify-content: center;
        align-items: center;
    }
    .modal.coursePopup .modal-content .cousreDetails__mainInfo .price>div {
        font-size: 1.2rem;
    }
    .price .usa {
        word-break: keep-all;
    }
    .price .india {
        word-break: keep-all;
    }
    .btn.green {
        padding: 17px 13px;
    }
    .modal.cartPopup .modal-content .modal-body__info .course__info {
        align-items: flex-start;
        gap: 10px;
    }
    .modal.cartPopup .modal-content .modal-body__info .course__image {
        width: 100%;
        height: 90px;
    }
    .c-sign{
        display: none;
    }
    .nav-top .logo {
        zoom: 0.6;
    }
    .nav-top {
        height: 80px;
    }
    .hideon-mobile{
        display: none;
    }
    .navbar-toggler.collapsed{
        order: 3;
        margin-right: 13px;
    }
    .banner-section .slick-dots{
        gap: 8px!important;
    }
    .row.g-4 .col-lg-3.col-md-6.col-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .feature__head {
        height: 230px;
    }
    .feature__body {
        padding: 24px 24px;
        gap: 15px;
        min-height: 298px;
    }
    .category-box .category {
        padding: 6px 0px;
        font-size: 1.16rem;
    }
    .price .usa, .price .india {
        font-size: 1.8rem;
    }
    .btn.off-text {
        font-size: 1.4rem;
    }
    .feature__body .feature-title h3 {
        font-size: 1.5rem;
        min-height: 49.22px;
    }
    .feature__body .feature-title p {
        font-size: 1.4rem;
    }
    .feature__body .feature-info>div span {
        font-size: 1.3rem;
        line-height: 116%;
    }
    .details-btn {
        font-size: 1.2rem;
    }
    .feature-rate .buttons button:nth-of-type(2) button.buy img{
        width:23px;
    }
    .main-title {
        font-size: 1.8rem;
    }
    .subscription__head .price .price__now .usa {
        font-size: 2.8rem;
    }
    .subscription__card h3 {
        font-size: 1.35rem;
    }
    .sclist li {
        font-size: 1.35rem;
    }
    .subscription__head .price .price__before {
        font-size: 1.3rem;
    }
    .subscription__head .discount {
        font-size: 1.1rem;
    }
    .ownPeace__card h3 {
        font-size: 2rem;
    }
    .section-head {
        font-size: 2.7rem;
    }
    .ownPeace__card p {
        font-size: 1.3rem;
    }
    .nav-bottom .nav-list .nav-link a {
        font-size: 15px;
    }
    .banner-section .slick-dots .banner-thumbs-slider-card .top p {
        font-size: 1rem;
    }
    .rbbox .buttons button:nth-of-type(2) button.buy.lp img{
        width:20px;
    }
    #contact {
        background:#020c30 url(../../../public/images/footer-mobile.svg)no-repeat top left;
    }
    .f-cards p {
        font-family: 'Grandstander', cursive;
        color: rgba(255, 255, 255, 0.7);
        font-size: 1.2rem;
        max-width: 310px;
    }
    p.pc-img{
        width: 90px;
        height: 90px;
        font-size: 2.2rem;
    }
    .pimg-sec .form-group>h5 {
        font-size: 1.5rem;
    }
    .profile-nav .nav-item>.nav-link {
        color: #8d8d8d;
        font-size: 1.3rem;
        padding-left: 20px;
    }
    .profile-nav {
        min-height: auto;
    }
    .rhs-box h6{
        font-size: 1.2rem;
        line-height: 25px;
    }
    .rhs-box h4{
        font-size: 1.7rem;
        margin-bottom: 9px!important;
    }
    table.table-dark.table-striped.table-bordered{
        font-size: 1.2rem;
    }
    .feature__body .feature-info>div {
        gap: 3px;
    }
    .modal-body-head p {
        font-size: 1.2rem;
    }
    .btn.primary.small, .details-btn.small {
        font-size: 1.2rem;
    }
    .spcd-box, .spcd-img, .spcdc-box {
        padding-left: 10px;
        padding-right: 10px;
    }
    .spcd-swbtn{
        margin-bottom: 30px;
    }
    .text-gray-700 {
        font-size: 1rem;
        color: #bbb6b6;
        font-weight: 400;
    }
    .text-base {
        font-size: 1.25rem;
        font-weight: 600;
        color: #F3F4F6;
    }
    .spcdc-box h3.curr-til{
        margin-top: 40px;
    }
    .spcd-box h5{
        font-weight: 600;
        font-size: 18px;
    }
    #basic-nav-dropdown::after {
        margin-left: -0.10em;
    }
    .dropdown-menu[data-bs-popper] {
        left: -18px;
    }
    .banner-section .slick-dots .banner-thumbs-slider-card .bottom .control .buttons {
        margin-left: auto;
    }



    button.buy {
        font-size: 1.4rem;
    }
    .accordion-button {
        font-size: 1.2rem;
    }
    .cousreDetails__subInfo {
        max-height: 200px!important;
        min-height: 200px!important;
    }
    .modal.coursePopup .modal-content .cousreDetails__mainInfo .buyContainer .buy {
        gap: 9px;
    }
    .modal.coursePopup .modal-content .cousreDetails__mainInfo .title h3 {
        font-size: 1.8rem;
    }
    .cousreDetails .right-section summary {
        font-size: 1.4rem;
    }
    .faq-answer>div{
        display: block!important;
    }
    .faq-answer>div img {
        width: 200px!important;
        margin-bottom: 10px;
    }
    .faqbox {
        margin-top: 10px;
    }
    .faq-question {
        font-size: 16px;
    }
    .faq-title {
        font-size: 22px;
    }
    .contact-form .content h2 {
        font-size: 2rem;
    }
    .contact-form .content span, .contact-form .content span a {
        font-size: 1.2rem;
    }
    .user.circle .svg-inline--fa{
        height: 1.2em;
    }
    .sbtn {
        font-size: 1.1rem;
    }
    #basic-nav-dropdown::after {
        content: "";
        border-top: .35em solid;
        border-right: .35em solid transparent;
        border-left: .35em solid transparent;
    }
    #basic-nav-dropdown::after {
        margin-left: -0.40em;
    }
    

}

input.form-control {
    display: flex;
    width: 100%;
    height: 58px;
    padding: 17px 30px;
    align-items: center;
    color: grey;
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
    border-radius: 15px;
    border: 1px solid rgba(192, 192, 192, 0.6);
    transition: 0.4s;
    background: #fff;
}

input.form-control:hover {
    background-color: transparent;
    border: 1px solid #fff;
}

input.form-control::placeholder {
    color: grey;
}

input.form-control:focus {
    background-color: #fff;
}

#loading {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    text-align: center;
    background-color: #000;
    z-index: 9999;
}

#loading img {
    width: 300px;
    animation: beat 0.25s infinite alternate;
    transform-origin: center;
}

@keyframes beat {
    to {
        transform: scale(1.1);
    }
}

.owl-stage {
    margin: auto;
    display: flex;
    flex-wrap: nowrap;
}

.owl-stage .owl-item {
    margin-right: 0 !important;
    margin-left: 30px !important;
}

.owl-dots,
.owl-nav.disabled {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer;
}


.banner-section .banner-carousel .banner-carousel-card {
    max-height: 716px;
}

.banner-section .banner-carousel .banner-carousel-card img {
    height: 716px;
    object-fit: cover;
    position: relative;
    z-index: 1;
}

.banner-section .banner-carousel .banner-carousel-card .video-container {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 2;
}

.banner-section .banner-carousel .banner-carousel-card .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 50%;
    min-width: 50%;
    margin: auto;
}

.banner-section .banner-carousel .banner-carousel-card .carousel-caption {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 25px;
    top: 30%;
    bottom: unset;
    left: 10%;
    right: 10%;
    z-index: 9;
    
}

.banner-section .banner-carousel .banner-carousel-card .carousel-caption .caption-head {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.banner-section .banner-carousel .banner-carousel-card .carousel-caption .caption-head h3 {
    color: #fff;
    font-family: "Nunito Sans", sans-serif;
    font-size: 3.75rem;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: .6px;
}

.banner-section .banner-carousel .banner-carousel-card .carousel-caption .caption-head p {
    color: rgba(255, 255, 255, .9);
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: .18px;
}

.banner-section .banner-carousel .banner-carousel-card .carousel-caption .caption-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.banner-section .banner-carousel .banner-carousel-card .carousel-caption .caption-tags .tag {
    display: flex;
    padding: 15px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    border: 1.5px solid rgba(255, 255, 255, .25);
    background: rgba(0, 0, 0, .05);
    box-shadow: 0 3px 13px 0 rgba(0, 0, 0, .2);
    backdrop-filter: blur(5px)
}

.banner-section .banner-carousel .banner-carousel-card .carousel-caption .caption-tags .tag i {
    font-size: 1.68rem;
    background: linear-gradient(144deg, #ff9362 21.05%, #fc6f2f 82.43%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.banner-section .banner-carousel .banner-carousel-card .carousel-caption button {
    width: 147px
}

.banner-section .banner-thumbs-slider {
    justify-content: flex-start;
    bottom: 100px;
    gap: 30px;
    margin-left: 10%;

}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card {
    background-color: transparent;
    text-indent: unset;
    position: relative;
    max-width: 100%;
    border-radius: 20px;
    border: 1.5px solid rgba(255, 255, 255, 0);
    box-shadow: 0 6.6313px 22.54642px 0 rgba(0, 62, 52, 0.2);
    overflow: hidden;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
    transition: 0.4s;
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card>img {
    min-height: 296px;
}

.banner-section .banner-thumbs-slider:hover .banner-thumbs-slider-card {
    filter: blur(5px);
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card:hover {
    filter: blur(0);
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card:hover .bottom {
    bottom: 0;
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card .top {
    position: absolute;
    padding: 16px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card .top h4 {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card .top p {
    display: inline-flex;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #244bd6;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card .bottom {
    position: absolute;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12.5px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    bottom: -68px;
    transition: 0.4s;
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card .bottom h3 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.18px;
}


.banner-section .banner-thumbs-slider .banner-thumbs-slider-card .bottom .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card .bottom .control .rate i,
.banner-section .banner-thumbs-slider .banner-thumbs-slider-card .bottom .control .rate span {
    color: #fff;
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card .bottom .control .buttons {
    display: flex;
    gap: 15px;
}

.banner-section .banner-thumbs-slider .banner-thumbs-slider-card .bottom .control .buttons .details-btn {
    color: #fff;
    border: 1.5px solid rgba(255, 255, 255, 0.25);
}


.tab-animation {
    transform: scale(0);
}

.tab-animation.active {
    transform: scale(1);
    animation: zoom-in-zoom-out 1s ease;
}


@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}




.banner-section .slick-slide .banner-carousel-card {
    max-height: 716px;
    position: relative;
}

.banner-section .slick-slide .banner-carousel-card img {
    height: 716px;
    object-fit: cover;
    position: relative;
    z-index: 1;
}

.banner-section .slick-slide .banner-carousel-card .video-container {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 2;
}

.banner-section .slick-slide .banner-carousel-card .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 50%;
    min-width: 50%;
    margin: auto;
}

.banner-section .slick-slide .banner-carousel-card .carousel-caption {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 25px;
    top: 30%;
    bottom: unset;
    left: 10%;
    right: 10%;
    z-index: 9;
}

.banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-head {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-head h3 {
    color: #fff;
    font-family: "Nunito Sans", sans-serif;
    font-size: 3.75rem;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: .6px;
    max-width: 1024px;
}

.banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-head p {
    color: rgba(255, 255, 255, .9);
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: .18px;
}

.banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-tags .tag {
    display: flex;
    padding: 15px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    border: 1.5px solid rgba(255, 255, 255, .25);
    background: rgba(0, 0, 0, .05);
    box-shadow: 0 3px 13px 0 rgba(0, 0, 0, .2);
    backdrop-filter: blur(5px)
}

.banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-tags .tag i {
    font-size: 1.68rem;
    background: linear-gradient(144deg, #ff9362 21.05%, #fc6f2f 82.43%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.banner-section .slick-slide .banner-carousel-card .carousel-caption button {
    width: 147px
}


/* navigations  */
.banner-section .slick-slider {
    position: relative;
    padding-bottom: 328px;
}

.banner-section .slick-dots {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex !important;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    margin-right: unset;
    width: 90%;
    overflow-x: auto;
    justify-content: flex-start;
    bottom: 100px;
    gap: 30px;
    margin-left: 10%;
    overflow: hidden;
}

.banner-section .slick-dots li {
    width: auto;
    height: auto;
    min-width: 465px;
    text-align: left;
}
.banner-section .slick-dots li a{
    display: block;
}

.banner-section .slick-dots .banner-thumbs-slider-card {
    background-color: transparent;
    text-indent: unset;
    position: relative;
    max-width: 100%;
    border-radius: 20px;
    border: 1.5px solid rgba(255, 255, 255, 0);
    box-shadow: 0 6.6313px 22.54642px 0 rgba(0, 62, 52, 0.2);
    overflow: hidden;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
    transition: 0.4s;
}

.banner-section .slick-dots .banner-thumbs-slider-card>img {
    /* min-height: 296px; */
}

.banner-section .banner-thumbs-slider:hover .banner-thumbs-slider-card {
    filter: blur(5px);
}

.banner-section .slick-dots .banner-thumbs-slider-card:hover {
    filter: blur(0);
}

.banner-section .slick-dots .banner-thumbs-slider-card:hover .bottom {
    bottom: 0;
}

.banner-section .slick-dots .banner-thumbs-slider-card .top {
    position: absolute;
    padding: 16px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0));

}

.banner-section .slick-dots .banner-thumbs-slider-card .top h4 {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
}

.banner-section .slick-dots .banner-thumbs-slider-card .top p {
    display: inline-flex;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #244bd6;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.banner-section .slick-dots .banner-thumbs-slider-card .bottom {
    position: absolute;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12.5px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    bottom: -68px;
    transition: 0.4s;
}

.banner-section .slick-dots .banner-thumbs-slider-card .bottom h3 {
	color: #fff;
	font-size: 1.4rem;
	font-weight: 600;
	line-height: 150%;
	letter-spacing: -0.30px;
	font-family: 'Nunito Sans';
}


.banner-section .slick-dots .banner-thumbs-slider-card .bottom .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.banner-section .slick-dots .banner-thumbs-slider-card .bottom .control .rate i,
.banner-section .slick-dots .banner-thumbs-slider-card .bottom .control .rate span {
    color: #fff;
}

.banner-section .slick-dots .banner-thumbs-slider-card .bottom .control .buttons {
    display: flex;
    gap: 15px;
}

.banner-section .slick-dots .banner-thumbs-slider-card .bottom .control .buttons .details-btn {
    color: #fff;
    border: 1.5px solid rgba(255, 255, 255, 0.25);
}



@media only screen and (max-width: 991.98px) {
    .banner-section .slick-slide .banner-carousel-card .carousel-caption {
        top: 23%;
    }

    .banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-head h3 {
        font-size: 2.65rem;
    }

    .banner-section .slick-slide .banner-carousel-card .carousel-caption.caption-head p {
        margin-bottom: 10px;
    }

    .banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-tags {
        margin-bottom: 10px;
    }
    .contact-form {
        flex-direction: column;
    }
    .contactdetails {
        margin-left: auto!important;
        margin-right: auto;
        margin-top: 27px;
        max-width: 633px;
    }
}


@media only screen and (max-width: 575.98px) {
    .banner-section .slick-dots { 
        bottom: 80px;
        margin-left: 5%;
    }

    .banner-section .slick-slide .banner-carousel-card .carousel-caption .caption-tags .tag {
        padding: 10px 15px;
        font-size: 14px;
    }

    .banner-section .slick-slide .banner-carousel-card img {
        height: 800px;
    }

    .banner-section .slick-dots .banner-thumbs-slider-card>img {
        min-height: 200px;
    }

    .banner-section .slick-dots .banner-thumbs-slider-card .bottom {
        padding: 15px;
    }

    .banner-section .slick-dots .banner-thumbs-slider-card .bottom {
        padding: 15px;
    }

    .banner-section .slick-dots li {
        min-width: 290px;
    }
    .subscription__card {
        gap: 15px;
        padding: 20px;
        max-width: calc(100% - 30px);
        margin: 0px auto;
    }
    .ownPeace__card .icon img {
       max-height:60px;
    }
    .ownPeace__card p{
        margin-bottom: 30px;
    }
    input.form-control {
        font-size: 1.12rem;
    }
    #contact .btn {
        font-size: 1.12rem;
    }
    .footer__links {
        letter-spacing: 0px;
        column-count: 2;
        flex-wrap: nowrap;
        display: block;
        width: calc(100% - 20px);
        margin: 0px auto;
    }
    .footer__links li{
        margin-bottom: 13px;
    }
    .footer__links a {
        font-size: 1.2rem;
    }
    .copyright__content ul {
        display: flex;
        gap: 15px;
    }
    .copyright__content ul li img {
        width: 30px;
        height: 30px;
        transition: 0.1s;
    }
    .copyright p, .copyright p a {
        font-size: 1.2rem;
    }
    .copyright__content {
        justify-content: center;
    }
    .fixed-nav .nav-top .logo img:first-child {
        height: 70px;
    }
    .fixed-nav .nav-top .logo img:last-child {
        height: 70px;
    }
    .banner-section .slick-dots {
        width: 95%;
    }
    .logo-slider::before, .logo-slider::after {
        width: 65px;
    }
    .ownPeace__card h3 {
        font-size: 1.7rem;
    }
    .feature__body .feature-title h3 {
        font-size: 1.5rem;
        min-height: 54px;
    }
    .swiper-slide {
        margin-right: 20px!important;
    }
    .controls .control {
        font-size: 1.2rem;
        padding: 15px 26px;
    }
    .ccbx {
        padding-top: 25px;
        padding-bottom: 43px;
    }
    .lpath__card h4 {
        opacity: 0.95;
        font-size: 1.8rem;
    }
    .lpath__card h6 {
        font-size: 1.3rem;
    }
    .lpath__card .btn.primary.mw200 {
        transition: 0.5s;
        position: relative;
        font-size: 1.2rem;
    }
    .containerr .card .face.face1 .content p.course-title {
        font-size: 1.4rem;
        line-height: 26px;
    }
    .containerr .card .face.face2 .content p, a {
        font-size: 1.3rem;
        line-height: 23px;
    }
    .roadmap-box .containerr .card .face.face2 .content .feature-info.rodmp {
        font-size: 1.2rem;
        line-height: 18px;
    }
    .rbbox .details-btn {
        height: 40px;
        font-size: 1rem;
    }
    .rbbox button {
        font-size: 1.3rem;
    }
    .roadmap-box .col-lg-4 .containerr .card .face.face1 .content:after {
        font-size: 1.6rem;
    }
    .faq-box {
        max-width: calc(100% - 20px);
    }
    .cf-a {
        font-size: 1.2rem;
    }
    .section-top-head p {
        margin-bottom: 30px;
        font-size: 1.12rem;
        line-height: 163%;
        max-width: 255px;
        margin-left: auto;
        margin-right: auto;
        font-weight: 600;
    }
    .tab-buttons.cftc button {
        padding-left: 20px;
        padding-right: 20px;
    }
    .blank-box h3, .blank-box h5 {
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        font-size: 16px;
    }
    .blank-box h3 {
        font-size: 20px;
    }
    .blank-box {
        background-image: linear-gradient(to right, #fc6f2f , #fc6f2f);
    }
    ul.timeline li a span.title-cf {
        line-height: 27px;
    }
    .contact-form {
        flex-direction: column;
    }
    .contactdetails {
        margin-left: auto!important;
        margin-right: auto;
        margin-top: 27px;
        max-width: 300px;
    }
}
.carousel-caption {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  
  .carousel-caption.fade-out {
    opacity: 0;
    animation: fly-out 0.5s forwards;
  }
  
  @keyframes fly-out {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-20px);
      opacity: 0;
    }
  }
iframe#webpack-dev-server-client-overlay{display:none!important}





